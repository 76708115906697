@import '../../functions';

.container {
  width: 100%;
  max-width: 100%;
  display: flex;
  position: relative;
}

.tabs {
  display: flex;
  align-items: flex-end;
  height: px_to_rem(30);
}

.selectList li {
  &:hover {
    button {
      text-decoration: none;
      outline: none;
      color: var(--primaryColor);
    }
  }

  button {
    width: 100%;
    display: block;
    text-align: start;
  }
}

.selectList,
.tab {
  button {
    position: relative;
    color: var(--textColor);

    &::before {
      content: attr(data-title);
      position: relative;
      overflow: hidden;
      height: 0;
      font-weight: bold;
      display: block;
      opacity: 0;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
      color: var(--primaryColor);
    }
  }
}

.tab {
  white-space: nowrap;
  overflow: hidden;
}

.active button {
  font-weight: bold;
  color: var(--primaryColor);
}

.moreButton {
  display: flex;
  opacity: 0;
  align-items: center;
  color: var(--textColor);

  &.active {
    color: var(--primaryColor);
  }

  .dots {
    margin-top: px_to_rem(-4);
  }

  .dot {
    display: flex;
    align-items: center;
    font-weight: bold;
    line-height: px_to_rem(4);
    height: px_to_rem(4);
  }

  .label {
    font-size: px_to_rem(16);
  }

  &.visible {
    opacity: 1;
  }
}

.selectList {
  transform: translateX(25%);
  margin-top: px_to_rem(10);
}

.line {
  background-color: var(--primaryColor);
  transition: left 0.5s ease, right 0.5s ease, width 0.5s ease;
  width: px_to_rem(100);
  height: px_to_rem(2);
  position: absolute;
  padding: 0 px_to_rem(8);
  margin-top: px_to_rem(29);

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: px_to_rem(4);
    height: px_to_rem(2);
    border-radius: 50%;
    background-color: inherit;
    top: 0;
    left: px_to_rem(-2);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: px_to_rem(4);
    height: px_to_rem(2);
    border-radius: 50%;
    background-color: inherit;
    top: 0;
    right: px_to_rem(-2);
  }
}

.container--chips {
  .line {
    display: none;
  }

  .tabs {
    .tab {
      button {
        position: relative;
        border: 1px solid #e0e0e0;
        border-radius: px_to_rem(16);
        color: var(--primaryColor);
        height: px_to_rem(33);
        justify-content: center;
        align-items: center;
        padding: 0 px_to_rem(23);
        background-color: transparent;
      }

      &.active {
        button {
          border: 1px solid var(--primaryColor);
          box-shadow: 0px 5px 9px #5750fa33;
          background-color: var(--primaryLightBackground);
        }
      }
    }
  }
}

[data-is-mobile-view='true'] {
  .container {
    transition: transform 0.25s cubic-bezier(0.47, 0, 0.75, 0.72);
  }

  .tabs {
    .tab{
    }
    .tab:nth-last-child(1) {
    }
  }

  .line {
    height: px_to_rem(3, 'mobile');
  
    &::before {
      height: px_to_rem(3, 'mobile');
    }
  
    &::after {
      height: px_to_rem(3, 'mobile');
    }
  }

  .container--chips {
    .tabs {
      height: px_to_rem(44);
      .tab{
      }
      .tab:nth-last-child(1) {
      }
    }
  }
}

@each $direction in (ltr, rtl) {
  $dir: $direction;
  $dirOpposite: if-rtl(ltr, rtl);
  $left: if-rtl(left, right);
  $right: if-rtl(right, left);

  [data-direction='#{$direction}'] {
    .tabs {
      padding-#{$right}: px_to_rem(10);
    }
    
    .tab {
      margin-#{$left}: px_to_rem(60);
    }
    
    .moreButton {
      .dot {
        margin-#{$left}: px_to_rem(8);
      }
    }
    
    &[data-is-mobile-view='true'] {
      .tabs {
        .tab{
          margin-#{$left}: px_to_rem(40, 'mobile');
        }
        .tab:nth-last-child(1) {
          margin-#{$left}: 0;
        }
      }
    
      .container--chips {
        .tabs {
          height: px_to_rem(44);
          .tab{
            margin-#{$left}: px_to_rem(10, 'mobile');
          }
          .tab:nth-last-child(1) {
            margin-#{$left}: 0;
          }
        }
      }
    }
  }
}