@import '../../functions';

.taskForm {
  position: relative;
  overflow: hidden;
  border-radius: px_to_rem(10);
  transition: box-shadow 0.5s ease-in-out;
}

.taskForm--white {
  box-shadow: 0 0 0 px_to_rem(2) transparent inset;
}

.taskForm--red {
  box-shadow: 0 0 0 px_to_rem(2) #f84444;
}

.taskForm--green {
  box-shadow: 0 0 0 px_to_rem(2) #24cd78;
}

.taskFormContainer {
  width: 100%;
  display: flex;
  flex-direction: row;

  .taskFormContainerItem {
    position: relative;
    width: 100%;
    max-height: 80vw;
    min-height: px_to_rem(350);
    height: fit-content;
    display: flex;
    flex-direction: column;
    transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;

    .taskFormContainerItemContent {
      flex: 1;
      padding: px_to_rem(36) px_to_rem(42);
    }

    .taskFormContainerItemImage {
      margin-top: px_to_rem(36);
      height: px_to_rem(130);
      background: url('mustLoginBg.png') no-repeat center;
      background-size: contain;
    }

    .taskFormContainerItemFooter {
      flex: none;
      border-top: 1px solid rgba(127, 127, 144, 0.28);
      padding: px_to_rem(0) px_to_rem(42);
      height: px_to_rem(82);
      display: flex;
      flex-direction: row;
      align-items: center;

      .taskFormContainerItemFooterSide_center {
        flex: 1;
        text-align: center;
      }
    }
  }
}

.footerButton {
  font-weight: bold;
  min-width: px_to_rem(114);
  height: px_to_rem(36);
}

.taskFormNoLoginTitle {
  font-size: px_to_rem(20);
  font-weight: 700;
  margin-bottom: px_to_rem(7);
}

[data-is-mobile-view='true'] {
  .taskForm {
    border-radius: 0;
    height: 100%;
  }

  .taskForm--white,
  .taskForm--red,
  .taskForm--green {
    box-shadow: unset;
  }

  .taskFormContainer {
    height: 100% !important;

    .taskFormContainerItem {
      min-height: unset !important;
      max-height: unset !important;
      height: 100% !important;

      .taskFormContainerItemContent {
        padding: px_to_rem(20, 'mobile') px_to_rem(20, 'mobile') px_to_rem(0, 'mobile') px_to_rem(20, 'mobile');
      }

      .taskFormContainerItemFooter {
        height: px_to_rem(74);
      }
    }
  }
}

@each $direction in (ltr, rtl) {
  $dir: $direction;
  $dirOpposite: if-rtl(ltr, rtl);
  $left: if-rtl(left, right);
  $right: if-rtl(right, left);

  [data-direction='#{$direction}'] {
    .taskFormContainer {
      margin-#{$right}: 0%;
      transition: margin-#{$right} 0.5s ease-in-out, height 0.5s ease-in-out;
    }
  }
}
