@import '../../functions';

.container {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 0 px_to_rem(16);
  border-radius: px_to_rem(4);
  z-index: 1;
}

.spout {
  position: absolute;
  width:  px_to_rem(8);
  height:  px_to_rem(8);
}

[data-direction='rtl'] .spout {
  top: 0;
  left: px_to_rem(-3);
  right: unset;
  transform: skew(-60deg, 0deg) rotateX(45deg);
}

[data-direction='ltr'] .spout {
  top: 0;
  right: px_to_rem(-3);
  left: unset;
  transform: skew(60deg, 0deg) rotateX(-45deg);
}
