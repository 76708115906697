@import "../../functions";

.container {
  //min-height: px_to_rem(455);
  max-height: calc(100vh - 8rem);
  height: px_to_rem(455);    
  display: flex;
  flex-flow: column;
  background: #ffffff;
  border-radius: px_to_rem(10);

  &.guestContainer {
    min-height: px_to_rem(350);
  }

  &.errorContainer{
    //box-shadow: 0 0 0 0.125rem #f84444 inset;
  }

  header {
    padding: px_to_rem(36) px_to_rem(42) px_to_rem(0);

    h1{
      margin-top: px_to_rem(11);
      margin-bottom: px_to_rem(20);
    }

    h2 {
      font-size: px_to_rem(20);
      font-weight: 700;
      margin-bottom: px_to_rem(7);
      line-height: px_to_rem(30);
    }

    &:not(.guestHeader){
      h2{
        color: var(--primaryLightText);

        &.error{
          color:#F84444;
        }
      }
    }
    .title{
      color: #F84444;
      position: relative;
    }

    .titleIcon {
      width: px_to_rem(24);
      height: px_to_rem(24);
      display: block;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      background-color: currentColor;
      border-radius: 50%;

      & > svg {
          position: absolute;
          width: px_to_rem(8) !important;
          height: px_to_rem(8) !important;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #ffffff;
          fill: currentColor;
          stroke: none;
      }
    }
  }

  main {
    padding: 0 px_to_rem(42);
    flex-grow: 1;

    > h3 {
      margin-bottom: px_to_rem(8);
    }

    &.message {
      margin: px_to_rem(32) 0;
    }

    .guestLine1{
      margin-top: px_to_rem(8);
    }

    .checkBoxList {
      border-radius: px_to_rem(8);
      max-height: px_to_rem(196);
      overflow-y: auto;
      padding: 0;
      margin-bottom: px_to_rem(20);
    }

    .addCategoryContainer {
      margin-bottom: px_to_rem(36);

      .input {
        input {
          border: none;
          border-bottom: solid px_to_rem(1) var(--primaryColor);
          color: var(--textColor);
          font-size: 1.1rem;
          width: 100%;

          &:focus {
            background-color: var(--primaryLightBackground);
            outline: none;
          }

          &.invalid {
            border-bottom-color: red;
          }
        }
      }
    }

    .mainImage{
      height: 130px;
      margin: px_to_rem(36) 0;
      background: url('mustLoginBg.png') no-repeat center;
      background-size: contain;
    }

  }

  footer {
    flex: none;
    border-top: 1px solid rgba(127, 127, 144, 0.28);
    padding: px_to_rem(0) px_to_rem(42);
    height: px_to_rem(82);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .btnPrimary,
    .btnNewCategory{
      height: px_to_rem(35.2);
      font-size: 1.1rem;
      padding: 0 px_to_rem(18);
    }

    .btnCancel{
      font-weight: bold;
    }

    .btnNewCategory {
      color: var(--primaryColor);
      border: px_to_rem(1) solid var(--primaryColor);
    }

    button:disabled {
      user-select: none;
    }

    &.guestFooter{
      justify-content: center;

      .footerButton {
        font-weight: bold;
        min-width: px_to_rem(114);
        height: px_to_rem(36);
      }
    }
  }
}

.popupError {
  box-shadow: 0 0 0 2px #ff0000, 0 -2px 0 2px #ff0000;
}

.modal {
  padding: px_to_rem(16);
}

[data-is-mobile-view='true'] {
  .container {
    min-height: unset;
    max-height: unset;
    height: unset;
    border-radius: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.guestContainer {
      min-height: unset;
    }
  
    &.errorContainer{
      box-shadow: unset;
    }

    .mobileBackButton {
      cursor: pointer;
      position: absolute;
      width: px_to_rem(28, 'mobile');
      height: px_to_rem(26, 'mobile');
      top: px_to_rem(15, 'mobile');
      z-index: 1;

      svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: px_to_rem(18, 'mobile') !important;
          height: px_to_rem(16, 'mobile') !important;
          pointer-events: none;
      }
    }

    .mobileCloseButton {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
      position: absolute;
      right: unset;
      left: unset;
      width: px_to_rem(31, 'mobile');
      height: px_to_rem(31, 'mobile');
      top: px_to_rem(17, 'mobile');
      background-color: #edecfe;
      box-shadow: 0 0 px_to_rem(6, 'mobile') px_to_rem(3, 'mobile') #38374526;
      border: px_to_rem(1, 'mobile') solid #DADADA;

      svg {
        stroke-width: 0;
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: px_to_rem(12, 'mobile') !important;
        height: px_to_rem(12, 'mobile') !important;
        fill: #5B5A6C;
      }
    }

    header {
      padding: px_to_rem(20, 'mobile') px_to_rem(20, 'mobile') px_to_rem(0) px_to_rem(20, 'mobile');

      h1 {
        font-size: px_to_rem(26, 'mobile');
        font-weight: bold;
        line-height: px_to_rem(28, 'mobile');
        margin-top: 0;
        margin-bottom: px_to_rem(14, 'mobile');
      }

      h2 {
        font-size: px_to_rem(20, 'mobile');
        font-weight: bold;
        line-height: px_to_rem(30, 'mobile');
        margin-top: 0;
        margin-bottom: px_to_rem(14, 'mobile');
      }
    }

    main {
      padding: 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      > h3 {
        padding: 0 px_to_rem(20, 'mobile');
        font-size: px_to_rem(18, 'mobile');
        font-weight: bold;
        line-height: px_to_rem(26, 'mobile');
        margin-top: 0;
        margin-bottom: px_to_rem(7, 'mobile');
        flex: none;
      }

      .checkBoxList {
        border-radius: px_to_rem(8);
        max-height: unset;
        overflow-y: auto;
        margin-bottom: 0;
        margin-top: 0;
        padding: 0 px_to_rem(20, 'mobile');
        flex: 1;
      }

      .addCategoryContainer {
        padding: 0 px_to_rem(20, 'mobile');
        margin-bottom: 0;
        flex: 1;

        .input {
          height: px_to_rem(34, 'mobile');
          line-height: px_to_rem(34, 'mobile');
          margin-top: px_to_rem(120, 'mobile');

          input {
            border: none;
            border-bottom: 1px solid var(--primaryColor);
            font-size: px_to_rem(18, 'mobile');
            box-sizing: border-box;
            height: 100%;
            line-height: px_to_rem(34, 'mobile');

            &:focus {
              background-color: var(--primaryLightBackground);
              outline: none;
            }

            &.invalid {
              border-bottom-color: red;
            }
          }
        }
      }

      .errorMessage {
        padding: 0 px_to_rem(20, 'mobile');
        font-size: px_to_rem(18, 'mobile');
      }

      .guestLine1, .guestLine2 {
        padding: 0 px_to_rem(20, 'mobile');
        font-size: px_to_rem(18, 'mobile');

        button {
          font-size: px_to_rem(18, 'mobile');
          text-decoration: underline;
        }
      }
    }

    footer {
      flex: none;
      height: px_to_rem(74, 'mobile');
      border-top: 1px solid #DFDFDF;
      padding: 0;
      position: relative;
      justify-content: center;

      .btnPrimary,
      .btnNewCategory {
        height: px_to_rem(41, 'mobile');
        font-size: px_to_rem(17, 'mobile');
        padding: 0 px_to_rem(21, 'mobile');
        min-width: px_to_rem(122, 'mobile');
      }

      .btnCancel{
        font-weight: bold;
        text-decoration: none;
      }

      .btnNewCategory {
        color: var(--primaryColor);
        border: 1px solid var(--primaryColor);
      }

      .btnCreate {
        min-width: px_to_rem(152, 'mobile');
      }

      button:disabled {
        user-select: none;
      }

      &.guestFooter{
        justify-content: center;

        .footerButton {
          font-weight: bold;
          font-size: px_to_rem(17, 'mobile');
          min-width: px_to_rem(146, 'mobile');
          height: px_to_rem(41, 'mobile');
        }
      }
    }
  }
}

@each $direction in (ltr, rtl) {
  $dir: $direction;
  $dirOpposite: if-rtl(ltr, rtl);
  $left: if-rtl(left, right);
  $right: if-rtl(right, left);

  [data-direction='#{$direction}'] {
    .container {
      header {
        .title{
          padding-#{$right}: px_to_rem(33);
        }
    
        .titleIcon {
          margin-#{$right}: px_to_rem(-33);
        }
      }
    
      main {
        .checkBoxList {
          padding-#{$left}: px_to_rem(8);
        }
      }
    
      footer {
        .btnNewCategory {
          margin-#{$left}: px_to_rem(10);
        }
      }
    }
    
    &[data-is-mobile-view='true'] {
      .container {
        .mobileBackButton {
          #{$left}: px_to_rem(12, 'mobile');
        }
    
        .mobileCloseButton {
          #{$left}: px_to_rem(11, 'mobile');
        }
    
        header {
          h2 {
            padding-#{$left}: px_to_rem(25, 'mobile');
          }
        }

        main {
          .checkBoxList {
            padding: 0 px_to_rem(20, 'mobile');
          }
        }
    
        footer {
          .btnCancel{
            margin-#{$left}: px_to_rem(82, 'mobile');
            margin-#{$right}: px_to_rem(20, 'mobile');
          }
    
          .btnNewCategory {
            margin-#{$left}: px_to_rem(24, 'mobile');
          }
        }
      }
    }
  }
}