@import '../../functions';

.video_play_btn {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    width: px_to_rem(75) !important;
    height: px_to_rem(75) !important;
    border-radius: 50%;
    background: rgba(87, 80, 250, 0.9);
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    border: none;
    padding: 0;
    cursor: pointer;
    color: #ffffff;
  
    &:hover {
        background: rgba(87, 80, 250, 1);
        transform: translate(-50%, -50%) scale(1.1);
    }
  
    svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
    }
}