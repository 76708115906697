.mobileLandscapeView {
    display: block;
    background: var(--primaryColor);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 92147483647;
    text-align: center;

    svg {
        height: 60%;
        position: relative;
        top: 20%;

        -webkit-animation-name: landscape-image-rotate;
        -webkit-animation-duration: 2.5s;
        -webkit-animation-iteration-count: infinite;
        animation-name: landscape-image-rotate;
        animation-duration: 2.5s;
        animation-iteration-count: infinite;

        .path1 {
            fill: none;
            stroke: #FFFFFF;
            stroke-width: 11;
            stroke-linecap: square;
            stroke-miterlimit: 10;
        }

        .path2 {
            fill: none;
            stroke: #FFFFFF;
            stroke-width: 11;
            stroke-miterlimit: 10;
            opacity: 0;

            -webkit-animation-name: landscape-image-v;
            -webkit-animation-duration: 2.5s;
            -webkit-animation-iteration-count: infinite;
            animation-name: landscape-image-v;
            animation-duration: 2.5s;
            animation-iteration-count: infinite;
        }

        .path3 {
            fill: #FFFFFF;
        }

    }

    
    @-webkit-keyframes landscape-image-rotate {
        0% {
          -ms-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        25% {
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        75% {
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        100% {
          -ms-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
    }

    @keyframes landscape-image-rotate {
        0% {
          -ms-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        25% {
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        75% {
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        100% {
          -ms-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
    }

    @-webkit-keyframes landscape-image-v {
        0% {
          opacity: 0;
        }

        25% {
          opacity: 0;
        }

        50% {
          opacity: 1;
        }

        75% {
          opacity: 0;
        }

        100% {
          opacity: 0;
        }
    }

    @keyframes landscape-image-v {
        0% {
          opacity: 0;
        }

        25% {
          opacity: 0;
        }

        50% {
          opacity: 1;
        }

        75% {
          opacity: 0;
        }

        100% {
          opacity: 0;
        }
    }
  }