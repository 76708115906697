@import '../../functions';

.form {
  background: linear-gradient(267.91deg, #5750FA 22%, #7771FA 108.49%);
  background-size: cover;
  position: relative;
  height: px_to_rem(220);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 3;
  flex-direction: column;
  padding-right: px_to_rem(32);

  &.home {
    background-image: url('./searchbox_background.png');
    height: px_to_rem(300);
    align-items: center;
  }
}

.header {

  >h1 {
    font-size: 2.5rem;
    font-weight: normal;
  }
}

.pageTitle {
  position: relative;
  margin-top: -9px;
  margin-bottom: 14px;

  >h1 {
    font-size: 2rem;
    font-weight: normal;
  }
}

.input {

  &.error {
    input::placeholder {
      color: #d71515;
    }
  }

  input {
    height: 100%;
    background-color: transparent;
    border: none;
    color: var(--textColor);
    font-size: px_to_rem(15);
    position: relative;
    z-index: 2;
    box-sizing: border-box;

    &:focus {
      background-color: var(--primaryLightBackground);
      outline: none;
    }
  }
}
.searchButtonWrapper {
  margin-right: 0.5vw;
}
.clearBtnWrapper {
  margin-right: 0.5vw;
  display: flex;
  align-items: center;
  position: relative;
  button {
    position: absolute;
    &:hover {
      text-decoration: none;
    }
  }
}
.selects_undo {
  display: flex;
  align-items: center;
  font-size: px_to_rem(18);
  color: white;

}
.selects_undo_icon {
  margin-left: px_to_rem(8);
  font-size: px_to_rem(5);
}

.topRowWrapper {
  position: relative;
  display: flex;

  .searchCancelButton {
    right: calc(29.65vw - #{px_to_rem(20)});
    position: absolute;
    cursor: pointer;
    z-index: 2;
    display: block;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    outline: none;
    height: 100%;

    svg {
      fill: currentColor;
      stroke: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

}

.spacer {
  width: 0;
  height: 80%;
  display: inline-block;
  font-size: 0;
  border-left: solid px_to_rem(2) #c0c0d0;
  opacity: 0.7;
}


.autoComplete {
  margin-top: px_to_rem(-23);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: -1;

  div[data-id='list'] {
    max-height: px_to_rem(430);
  }

  li {
    margin: px_to_rem(12) 0;
  }
}

.selectList {
  width: 100%;
  margin-top: px_to_rem(-14);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-sizing: border-box;
  z-index: -1;

  ul {
    margin-top: px_to_rem(16);
  }
}

.searchButton {
  width: px_to_rem(36);
  height: px_to_rem(36);
  border-radius: 50%;
  padding: 0;
  box-shadow: none;
  outline: none;

  &:focus,
  &:hover {
    background-color: var(--lightBackgroundColor);
  }

  svg {
    fill: var(--secondaryColor);
    // fill: var(--subTextColor);
    stroke: none;
  }

  &.open {
    svg {
      fill: var(--secondaryColor);
    }
  }
}

.open .searchButton {
  height: px_to_rem(30);
  width: px_to_rem(30);
  // animation: fadeButton 0.5s;
  position: absolute;
  left: px_to_rem(7);
  right: unset;

  &:focus {
    border: solid px_to_rem(2) var(--primaryColor);
  }
}

[data-direction='ltr'] .open .searchButton {
  left: unset;
  right: px_to_rem(7);
}

@keyframes fadeButton {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.subText {
  display: inline-block;
  color: var(--subTextColor);
}


.searchButton {
  width: px_to_rem(36, 'mobile');
  height: px_to_rem(36, 'mobile');
  top: px_to_rem(4.2, 'mobile');
  position: relative;
  overflow: hidden;

  svg {
    fill: var(--subTextColor);
  }

  &.open {
    svg {
      fill: var(--secondaryColor);
    }
  }
}


.topRowWrapper {
  position: relative;

  .searchButton {
    position: absolute;
    top: px_to_rem(10, 'mobile');
    width: px_to_rem(32, 'mobile');
    height: px_to_rem(32, 'mobile');
    z-index: 3;
  }

  .input {
    display: block;
    flex: none;
    background: var(--secondaryColor);
    border-top-right-radius: px_to_rem(5, 'mobile');
    border-bottom-right-radius: px_to_rem(5, 'mobile');
    border: 1px solid var(--primaryColor);
    border-left: unset;

    input {
      font-size: px_to_rem(18, 'mobile');
      width: 29.65vw;

      &:focus {
        background-color: transparent;
      }
    }

    .autoComplete {
      position: absolute;
      top: px_to_rem(66, 'mobile');
      //max-width: px_to_rem(727);
      width: 53.7vw;
      max-height: calc(100vh - #{px_to_rem(266, 'mobile')});
      overflow: auto;
      font-size: px_to_rem(18, 'mobile');
      line-height: px_to_rem(43, 'mobile');

      li {
        margin: 0;
        border-radius: px_to_rem(10, 'mobile');

        &:focus,
        &:hover {
          background-color: var(--paleLavender);
        }

        div {
          display: inline;
        }

        .autocompleteOption {
          display: flex;

          [data-dimension='ageGrades'] {
            order: 2;
            padding-inline-start: px_to_rem(24);
          }

          [data-dimension='disciplines'] {
            order: 3;
            padding-inline-start: px_to_rem(30);
          }
        }

        .autocompleteTitle {
          width: 28vw;
        }

        .autocompleteDimension {
          width: px_to_rem(140);
          display: inline-block;
          color: var(--subTextColor);
        }
      }
    }
  }

}

.selectorsWrapper {
  position: relative;
  background: var(--secondaryColor);
  border-top-left-radius: px_to_rem(5, 'mobile');
  border-bottom-left-radius: px_to_rem(5, 'mobile');
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryColor);
  border-right: unset;

  &>div {
    height: 100%;
  }

  &>.spacer {
    flex: none;
    height: px_to_rem(26, 'mobile');
    padding: px_to_rem(calc((53 - 30) / 2), 'mobile') 0;
  }

  .selectButton {
    display: flex;
    align-items: center;
    margin: 0;
    width: 12vw;
    height: 100%;
    font-size: px_to_rem(16, 'mobile');
    justify-content: space-around;
    &>div {
      height: 100%;
      width: 100%;
      &>div {
        width: 100%;
        height: 100%;
      }
    }
    svg {
      stroke: var(--primaryColor);
    }
  }

  svg {
    stroke-width: 2;
    path {
      stroke: var(--primaryColor);
    }
    transition: transform 300ms;
  }

  [data-is-open='true'] {
    svg {
      transform: scaleY(-1);
    }
  }

}
.subjectsSelectorsWrapper {
  height: 54px;
  width:  54.3vw;
  &.selectorsWrapper {
    border: unset;
    background: none;
    margin: px_to_rem(8) 0;
    > div {
      width: 50%;
    }
    button {
      background: var(--secondaryColor);
      border: 1px solid var(--primaryColor);
      border-radius: px_to_rem(5, 'mobile');
    }
  }
}
[data-is-mobile-view='false'] {
  .form {
    display: grid;
    align-content: center;
  }
  .header {
    text-align: center;
    height: px_to_rem(50);
  }
  .subjectsSelectorsWrapper {
    z-index: -2;
    &.selectorsWrapper {
      button {
        justify-content: space-between;
      }
    }
  }

}
[data-is-mobile-view='true'] {
  .form {
    height: px_to_rem(500);
    background-size: cover;
    padding-right: 0;
  }

  .header {
    position: absolute;
    transform: translateY(px_to_rem(-190));
  }
  .pageTitle {
    margin-right: px_to_rem(20);
  }

  .topRowWrapper {
    flex-direction: column;
    gap: px_to_rem(10);
    padding: px_to_rem(20);
    width: 100vw;

    .input {
      height: px_to_rem(64);
      border-radius: px_to_rem(6.8);
      border-left: 1px solid var(--primaryColor);

      input {
        width: 100%;
      }

      .autoComplete {
        width: calc(100vw - 40px);
        height: unset;
        padding: px_to_rem(10);
        z-index: 2000;
        border-bottom-left-radius: px_to_rem(6.8);
        border-bottom-right-radius: px_to_rem(6.8);
      }
    }

    .searchCancelButton {
      height: initial;
      top: px_to_rem(52);
      left: px_to_rem(30);
      right: unset;
    }

    .subjectsSelectorsWrapper {
      width: 100%;
      gap: 2vw;
    }
  }

  .selectorsWrapper {
    height: px_to_rem(64);
    margin: 0;
    background: initial;
    border: none;
    gap: 2vw;

    +button {
      margin: 0;
      color: var(--primaryColor);
    }

    &>div {
      width: 50%;
      background: var(--secondaryColor);
      border-radius: px_to_rem(6.8);
      border: 1px solid var(--primaryColor);
    }

    .spacer {
      display: none;
    }

    .selectButton {
      width: 100%;
      justify-content: space-between;
    }
  }

  .clearBtnWrapper {
    margin-top: px_to_rem(10);
  }
}

@each $direction in (ltr, rtl) {
  $dir: $direction;
  $dirOpposite: if-rtl(ltr, rtl);
  $left: if-rtl(left, right);
  $right: if-rtl(right, left);

  [data-direction='#{$direction}'] {
    .input {
      input {
        padding-#{$right}: px_to_rem(16);
        padding-#{$left}: px_to_rem(25);
      }
    }

    .selectList {
      margin-#{$right}: 0;
    }

    .open .searchButton {
      margin-#{$right}: px_to_rem(16);
    }

    .autocompleteDimension {
      margin-#{$right}: px_to_rem(4);
    }

    .topRowWrapper {
      .searchButton {
        #{$left}: px_to_rem(10, 'mobile');
        margin-#{$right}: 0;
      }

      .searchCancelButton {
        width: px_to_rem(20, 'mobile');

        svg {
          width: px_to_rem(12, 'mobile') !important;
        }
      }

    }
  }

}