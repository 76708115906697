@import "../../functions";

.container {
  padding: px_to_rem(5);
  border: solid px_to_rem(1) #e0e0e0;
  border-radius: px_to_rem(5);
  height: px_to_rem(35);
  line-height: px_to_rem(35);
  margin: px_to_rem(5) 0;

  &.isChecked {
    background-color: #ecebff;
    border-color: var(--primaryColor);
  }

  input[type="checkbox"] {
    cursor: pointer;
    height: px_to_rem(17);
    width: px_to_rem(17);
    margin: 0;
    vertical-align: text-top;
  }

  .label{
    cursor: pointer;
  }

  .isChecked {
    color: var(--primaryColor);
  }
}

[data-is-mobile-view='true'] {
  .container {
    cursor: pointer;
    width: unset;
    height: px_to_rem(44, 'mobile');
    line-height: px_to_rem(44, 'mobile');
    padding: 0 px_to_rem(12, 'mobile');
    margin: px_to_rem(8, 'mobile') 0;

    input[type="checkbox"] {
      cursor: pointer;
      height: px_to_rem(17, 'mobile');
      width: px_to_rem(17, 'mobile');
      margin: 0;
      pointer-events: none;
    }

    .label{
      cursor: pointer;
      pointer-events: none;
      font-size: px_to_rem(16, 'mobile');
    }
  }
}

@each $direction in (ltr, rtl) {
  $dir: $direction;
  $dirOpposite: if-rtl(ltr, rtl);
  $left: if-rtl(left, right);
  $right: if-rtl(right, left);

  [data-direction='#{$direction}'] {
    .container {
      input[type="checkbox"] {
        margin-#{$right}: px_to_rem(10);
      }
    
      .label{
        margin-#{$right}: px_to_rem(10);
      }
    }
    
    &[data-is-mobile-view='true'] {
      .container {
        .label{
          margin-#{$right}: px_to_rem(18.5, 'mobile');
        }
      }
    }
  }
}