@import '../../functions';

$sidebar-width: 240;
$sidebar-width-compact: 40;

.app {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    display: block;
    position: fixed;
    top: px_to_rem(60);
    bottom: 0;
    pointer-events: none;
    position: absolute;
  }

  &::before {
    background-color: #f0f1f6;
  }

  &::after {
    box-shadow: 0 0 px_to_rem(7) var(--shadowColor) inset;
    z-index: 1;
  }

  &[data-lang='ar'] {
    font-family: 'Abraham';

    * {
      font-family: 'Abraham';
    }
  }
}

.app.minimizedSidebar {
  footer {
    margin: 0;
  }
}

.page {
  position: relative;
  overflow: hidden;
  margin-bottom: px_to_rem(180);
  margin-top: px_to_rem(60);
  min-height: calc(100vh - #{px_to_rem(211)});
  padding: px_to_rem(20) 0 px_to_rem(32) 0;
}

.loader {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: px_to_rem(250);
    height: px_to_rem(250);
  }
}

.search {
  z-index: 1;
}

[data-page='search'] [data-is-mobile-view='true'] {
  .container {
    height: 0;
    left: 100vw;
    pointer-events: none;
  }
}

.content {
  margin: px_to_rem(32) px_to_rem(43);

  p {
    font-size: px_to_rem(18);
    line-height: px_to_rem(32);
  }

  .continueButton {
    text-decoration: underline;
  }
}

.title {
  color: var(--primaryColor);
}

.buttons {
  border-top: solid 1px #ccc;
  padding: px_to_rem(24) 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  padding: 0 px_to_rem(32);
}

.continueButton {
}

.notifications {
}

[data-direction='rtl'] {
  .app {
    &::before,
    &::after {
      right: px_to_rem($sidebar-width);
      left: 0;
      border-top-right-radius: px_to_rem(6);
    }
  }

  .app.minimizedSidebar {
    &::after {
      right: px_to_rem($sidebar-width-compact); // compact mode
      transition: right 0.3s ease;
    }
  }

  .noSideBar {
    &::before,
    &::after {
      right: 0;
    }
  }
}

[data-direction='ltr'] {
  .app {
    &::before,
    &::after {
      left: px_to_rem(200);
      right: 0;
      border-top-left-radius: px_to_rem(6);
    }
  }
}

[data-is-mobile-view='false'] {
  .sidebarContainer {
    position: absolute;
    margin-top: px_to_rem(60);
    width: px_to_rem($sidebar-width);
    z-index: 4;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - #{px_to_rem(60)});
    transition: width 0.3s ease;
  }

  .sidebarContainer.minimizedSidebar {
    width: px_to_rem($sidebar-width-compact); //compact mode
    transition: width 0.3s ease;
    aside {
      background: #fff;
      overflow-y: hidden;
    }
    &:hover {
      width: px_to_rem($sidebar-width);
    }
  }

  /* Overlay effect */
  .sidebarContainer.minimizedSidebar::before {
    content: ''; /* Initial state is no overlay */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0; /* Start with opacity 0 */
    transition: opacity 0.3s ease; /* Smooth transition for the overlay */
    pointer-events: none; /* Ensure the overlay doesn't interfere with user interactions */
  }

  /* Show the overlay only when hovering */
  .sidebarContainer.minimizedSidebar:hover::before {
    opacity: 1;
  }
}

[data-is-mobile-view='true'] {
  .sidebarContainer {
    position: fixed;
    bottom: 0;
    z-index: 4;
  }

  .app {
    background: #f0f1f6;
    padding: 0;

    &::before,
    &::after {
      content: none;
    }
  }

  .page {
    margin-top: px_to_rem(50, 'mobile');
    margin-bottom: px_to_rem(2, 'mobile');
    min-height: calc(100vh - #{px_to_rem(140, 'mobile')});
    padding: px_to_rem(17, 'mobile') 0 0;
  }

  .content {
    margin: unset;
    padding: px_to_rem(36, 'mobile') px_to_rem(21, 'mobile');

    .title {
      color: var(--textColor);
      margin: 0;
      margin-bottom: px_to_rem(7, 'mobile');
      font-size: px_to_rem(20, 'mobile');
      line-height: px_to_rem(30, 'mobile');
      font-weight: bold;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: px_to_rem(17, 'mobile');
      line-height: px_to_rem(25, 'mobile');

      .changeRoleBtn {
        font-size: px_to_rem(17, 'mobile');
        line-height: px_to_rem(25, 'mobile');
        text-decoration: underline;
      }
    }
  }

  .buttons {
    border-top: unset;
    padding: unset;
    padding-bottom: px_to_rem(44, 'mobile');
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;

    .continueButton {
      text-decoration: underline;
      margin: 0;
      padding: 0;
      font-size: px_to_rem(17, 'mobile');
      line-height: px_to_rem(25, 'mobile');
      font-weight: bold;
    }

    .button {
      padding: 0 px_to_rem(10, 'mobile');
      margin-bottom: px_to_rem(31, 'mobile');
      min-width: px_to_rem(154, 'mobile');
      height: px_to_rem(42, 'mobile');
      box-sizing: border-box;
      font-size: px_to_rem(17, 'mobile');
      font-weight: bold;
    }
  }
}

[data-page='search'] [data-is-mobile-view='true'] {
  .app {
    .page {
      margin-top: px_to_rem(0, 'mobile');
      padding-top: px_to_rem(0, 'mobile');
      min-height: calc(100vh - #{px_to_rem(90, 'mobile')});
    }
  }
}

@each $direction in (ltr, rtl) {
  $dir: $direction;
  $dirOpposite: if-rtl(ltr, rtl);
  $left: if-rtl(left, right);
  $right: if-rtl(right, left);

  [data-direction='#{$direction}'] {
    .page {
      margin-#{$right}: px_to_rem($sidebar-width);
    }

    .page.minimizedSidebar {
      margin-#{$right}: px_to_rem($sidebar-width-compact); // compact mode
    }

    .continueButton {
      margin-#{$left}: px_to_rem(32);
    }

    .noSideBar {
      .page {
        margin-#{$right}: 0;
      }

      footer {
        margin: 0;
      }
    }

    .search {
      margin-#{$right}: px_to_rem(10);
    }

    .notifications {
      margin-#{$right}: px_to_rem(10);
      margin-#{$left}: px_to_rem(10);
    }

    &[data-is-mobile-view='true'] {
      .app {
        .page {
          margin-#{$right}: 0;
        }
      }

      .search {
        margin-#{$right}: px_to_rem(0, 'mobile');
        margin-#{$left}: px_to_rem(0, 'mobile');
      }

      .notifications {
        margin-#{$right}: 0;
        margin-#{$left}: 0;
      }

      .sidebarContainer {
        #{$right}: 0;
      }
    }
  }
}

.errorPage {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .errorPage_image {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .errorPage_title {
    position: relative;
    text-align: center;
    color: #5750fa;
    line-height: 1.3;
    direction: rtl;
    font-size: 4vw;
    margin-top: 2vw;

    * {
      font-family: 'AlmoniNeue';
    }

    [data-lang='en'] {
      direction: ltr;
    }

    [data-lang='ar'] {
      * {
        font-family: 'Abraham';
      }
    }
  }

  &[data-layout='1'] {
    .errorPage_image {
      bottom: unset;
      top: 37vw;
    }

    .errorPage_title {
      font-size: 8vw;
    }
  }
}
