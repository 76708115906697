@import '../../functions';

.list {
  position: absolute;
  margin: 0;
  border-radius: px_to_rem(10);
  outline: none;
  background-color: #ffffff;
  width: auto;
  min-width: 100%;
  box-shadow: 0px 0px px_to_rem(7) #5e5d7e58;
  transition: opacity 0.2s, max-height 0.2s;
  overflow: hidden;
  z-index: 99;

  .container {
    max-height: px_to_rem(250);
    overflow: auto;
  }

  ul {
    list-style: none;
    margin: px_to_rem(10);
    padding: 0;
    width: calc(100% - #{px_to_rem(20)});
    outline: none;
    direction: ltr;
    overflow: hidden;
  }

  &.columns {
    display: flex;
    justify-content: space-between;

    ul {
      flex-grow: 1;

      display: inline-block;
      width: auto;
    }
  }

  li {
    margin: px_to_rem(5) 0;
    padding: px_to_rem(5) px_to_rem(10);
    cursor: pointer;
    display: flex;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--textColor);
    align-items: center;
    &.separator {
      pointer-events: none;
      display: none;
    }
    &.focused,
    &:focus,
    &:hover {
      background-color: var(--lightBackgroundColor);
      border-radius: px_to_rem(4);
    }

    &.title {
      color: var(--subTextColor);

      &.focused,
      &:focus,
      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.title {
  color: var(--subTextColor);

  li:hover {
    background-color: transparent;
    cursor: default;
  }
}

[data-direction='rtl'] .list li {
  direction: rtl;
  text-align: right;
}

.visible {
  opacity: 1;
  max-height: px_to_rem(320);
}

.hidden {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  pointer-events: none;
}

.checkbox {
  border: 1px solid #38374566;
  border-radius: 3px;
  display: inline-block;
  overflow: hidden;
  width: px_to_rem(16);
  height: px_to_rem(16);
  padding: px_to_rem(1);
  box-sizing: border-box;

  &.checked {
    background-color: var(--primaryColor);
    color: #ffffff;
    position: relative;

    svg {
      fill: #ffffff;
    }
  }

  svg {
    position: absolute;
    stroke-width: 0;
  }

  input {
    visibility: hidden;
    position: absolute;
  }
}

.saveButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px #38374566;
  padding: px_to_rem(16) px_to_rem(16) px_to_rem(4) px_to_rem(16);
}

button.clear {
  font-size: px_to_rem(14);
}

button.save {
  padding: px_to_rem(16);
  background: var(--primaryColor);
}


[data-is-mobile-view='true'] {
  .list {
    position: relative;
    border-radius: 0;
    box-shadow: unset;

    .listWrapper {
      display: flex;
      flex-direction: column;
    }

    .container {
      min-height: unset;
      max-height: unset !important;
      flex: 1;
    }

    .saveButtonContainer {
      flex: none;
    }

    ul {
      margin: 0;
      padding: 0;
      width: 100%;
    }

    li {
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;

      &.focused {
        background-color: #E0E0F8; 
      }

      &.title {
        &.focused {
          background-color: transparent; 
        }
      }
    }
  }
  .visible, .hidden {
    min-height: unset;
    max-height: unset !important;
    opacity: 1;
    pointer-events: all;
    width: fit-content;
    height: fit-content;
  }
  .hidden{
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
}

[data-is-mobile='true'] {
  .list {
    li {
      &:focus,
      &:hover {
        background-color: transparent;
      }
    }
  }
}

@each $direction in (ltr, rtl) {
  $dir: $direction;
  $dirOpposite: if-rtl(ltr, rtl);
  $left: if-rtl(left, right);
  $right: if-rtl(right, left);

  [data-direction='#{$direction}'] {
    .list {
      &.columns {
        ul {
          margin-#{$left}: px_to_rem(21);
        }
      }
    }
    
    .checkbox {
      margin-#{$left}: px_to_rem(14);
    }
    
  }
}