@import '../../functions';

.stuckMenu {
    background-color: #f0f1f6;
    width: 100%;
    border-top-right-radius: px_to_rem(6);
    border-top-left-radius: px_to_rem(6);
    box-sizing: border-box;
    z-index: 1;

    .contentPadding {
        padding: 0 px_to_rem(32);
    }
    
    &::after {
        content: '';
        display: block;
        height: 1px;
    }
    .stuckUp {
        box-shadow: 0 px_to_rem(9) px_to_rem(7) var(--shadowColor);
    }

    $animationStep: 0.2s;
    $animationStepDelay: 0.1s;
    .layoutCenter {
        position: relative;
    }
    &[data-is_top="false"] {
        position: relative;
    }
    &[data-is_top="true"] {
        position: fixed;
    }

    .layoutBottom {

    }

    .layoutStart {
        position: absolute;
        top: px_to_rem(16);
    }

    .layoutEnd {
        position: absolute;
        top: px_to_rem(16);
    }
}

[data-direction="rtl"] .stuckMenu {
    .layoutStart {
        right: px_to_rem(32);
    }

    .layoutEnd {
        left: px_to_rem(32);
    }
}

[data-direction="ltr"] .stuckMenu {
    .layoutStart {
        left: px_to_rem(32);
    }

    .layoutEnd {
        right: px_to_rem(32);
    }
}

[data-is-mobile-view='true'] {
    .contentPadding {
      padding: 0 px_to_rem(20, 'mobile');
      position: relative;
    }
  
    &[data-direction="rtl"] .stuckMenu {
        .layoutStart {
            right: px_to_rem(20, 'mobile');
        }
    
        .layoutEnd {
            left: px_to_rem(20, 'mobile');
        }
    }
    
    &[data-direction="ltr"] .stuckMenu {
        .layoutStart {
            left: px_to_rem(20, 'mobile');
        }
    
        .layoutEnd {
            right: px_to_rem(20, 'mobile');
        }
    }
}


@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .stuckMenu {
            $animationStep: 0.2s;
            $animationStepDelay: 0.1s;
            &[data-is_top="false"] {
                .layoutCenter {
                    transition: #{$animationStep} padding-top 0s ease-in-out, #{$animationStep} margin-#{$right} #{$animationStepDelay} ease-in-out, #{$animationStep} margin-#{$left} #{$animationStepDelay} ease-in-out;
                }
            }
            &[data-is_top="true"] {
                .layoutCenter {
                    transition: #{$animationStep} padding-top #{$animationStepDelay} ease-in-out, #{$animationStep} margin-#{$right} 0s ease-in-out, #{$animationStep} margin-#{$left} 0s ease-in-out;
                }
            }
        }
        
        &[data-is-mobile-view='true'] {
            .contentPadding {
                #{$right}: -0.5rem;
            }
        }
    }
}