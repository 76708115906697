@import '../../functions';

.container{
  z-index: 6;
  background-color: rgba(0, 0, 0, 1);
}

.content {
  margin: px_to_rem(32) px_to_rem(43);

  p {
    font-size: px_to_rem(18);
    line-height: px_to_rem(32);
  }
}

.title {
  color: var(--primaryColor);
}

.buttons {
  border-top: solid 1px #ccc;
  padding: px_to_rem(24) 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  padding: 0 px_to_rem(32);
}

[data-is-mobile-view='true'] {
  .content {
    margin: unset;
    padding: px_to_rem(36, 'mobile') px_to_rem(21, 'mobile');
  
    .title {
      margin: 0;
      padding: 0;
      color: var(--textColor);
      font-size: px_to_rem(20, 'mobile');
      line-height: px_to_rem(30, 'mobile');
      margin-bottom: px_to_rem(7, 'mobile');
    }
  
    p {
      margin: 0;
      padding: 0;
      font-size: px_to_rem(17, 'mobile');
      line-height: px_to_rem(25, 'mobile');
    }
  }
  
  .buttons {
    border-top: unset;
    padding: unset;
    padding-bottom: px_to_rem(47, 'mobile');
    display: flex;
    justify-content: center;
    align-items: center;
  
    .button {
      padding: 0 px_to_rem(10, 'mobile');
      font-size: px_to_rem(17, 'mobile');
      font-weight: bold;
      min-width: px_to_rem(122, 'mobile');
      height: px_to_rem(42, 'mobile');
      box-sizing: border-box;
    }
  }
}