@import '../../functions';
$location: px_to_rem(15);

.container {
  position: fixed;
  z-index: 30000;
  opacity: 0.9;
  bottom: $location;
  padding: 0 $location;
  margin: px_to_rem(5);
  background: #363636;
  color: white;
  border: 1px solid #fff;
  border-radius: px_to_rem(4);
  box-shadow: 0px px_to_rem(4) px_to_rem(7) 0px #00000052;

  p{
    margin: px_to_rem(10) 0;
  }


  &.fadeOut {
    transition: opacity 1s linear;
    opacity: 0;
    pointer-events: none;
  }
}

[data-direction='rtl'] .container {
  right: $location;
}

[data-direction='ltr'] .container {
  left: $location;
}

[data-is-mobile-view='true'] {
  .container {
    opacity: 1;
    transition: opacity 0.25s linear;
    font-size: px_to_rem(16, 'mobile');
    bottom: px_to_rem(74, 'mobile');
    //bottom: px_to_rem(60, 'mobile');
    left: 0 !important;
    right: 0 !important;
    text-align: center;
    padding: px_to_rem(5.5, 'mobile');
    margin: 0;
    background: #24CD78;
    color: white;
    border: unset;
    border-radius: unset;
    box-shadow: unset;
    pointer-events: none;
  
    p{
      margin: 0;
      padding: 0;
    }

    &.fadeOut {
      opacity: 0;
    }
  }
}

