@import '../../functions';

.searchBoxPopUpWrapper {
  form {
    border-radius: px_to_rem(20);
    background-image: none;
    background-color: var(--primaryColor);
    align-items: center;
    height: px_to_rem(300);
    padding-right: px_to_rem(0);
  }

  main {
    padding: 0;
    margin: 0;
    overflow: visible;
  }

  dialog {
    background-color: unset;
    margin-top: px_to_rem(100);
  }
}

[data-is-mobile-view='true'] {

  .searchBoxPopUpWrapper {
    dialog {
      margin-top: px_to_rem(70);

      button {
        box-shadow: none;
      }
    }

    form {
      height: 100%;
      border-radius: unset;
      align-items: flex-start;

      >div {
        margin-top: px_to_rem(150);
      }
    }

  }
}