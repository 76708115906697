@import '../../functions';

.dropBox { 
  position: fixed;
  //width: 100vw;
  //height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 92147483647;

  .dropBox_bg {
    background: #383745;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .dropBox_box {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: unset;
    max-height: 66%;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translate(0, 100%);
    transition: transform 0.5s ease-in-out;
    font-size: px_to_rem(20, 'mobile');
    color: var(--textColor);
    border-radius: px_to_rem(19, 'mobile') px_to_rem(19, 'mobile') 0 0;
    overflow: hidden;
  }

  &.dropBox_type_fullscreen {
    .dropBox_box {
      height: 100%;
      max-height: 100%;
      border-radius: 0;
    }
  }

  &.dropBox_active {
      pointer-events: all;

      .dropBox_bg {
        opacity: 0.85;
      }

      .dropBox_box { 
        transform: translate(0, 0%);
      }
  }

  .dropBox_content {
    flex: 1;
    padding: px_to_rem(19, 'mobile') px_to_rem(30, 'mobile') 0 px_to_rem(30, 'mobile');
    overflow: auto;
  }

  .dropBox_closeBtn {
    display: none;
    flex: none;
    cursor: pointer;

    &.dropBox_closeBtn_top {
      display: block;
      position: absolute;
      top: px_to_rem(10, 'mobile');
      width: px_to_rem(20, 'mobile');
      height: px_to_rem(20, 'mobile');
      z-index: 1;

      .dropBox_closeBtn_icon {
        width: px_to_rem(20, 'mobile') !important;
        height: px_to_rem(20, 'mobile') !important;
        fill: currentColor;
        stroke: none;
      }

      .dropBox_closeBtn_label {
        display: none;
      }
    }

    &.dropBox_closeBtn_bottom {
      display: block;
      position: relative;
      text-align: center;
      line-height: px_to_rem(77, 'mobile');

      .dropBox_closeBtn_icon {
        display: none;
      }

      .dropBox_closeBtn_label {
        
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: px_to_rem(30, 'mobile');
        right: px_to_rem(30, 'mobile');
        height: 1px;
        background: #898888;
      }
    }
  }
}

@each $direction in (ltr, rtl) {
  $dir: $direction;
  $dirOpposite: if-rtl(ltr, rtl);
  $left: if-rtl(left, right);
  $right: if-rtl(right, left);

  [data-direction='#{$direction}'] {
    .dropBox {
      .dropBox_closeBtn {
        &.dropBox_closeBtn_top {
          #{$left}: px_to_rem(10, 'mobile');
        }
      }
    }
  }
}