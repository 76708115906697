@import '../../functions';

.button {
  border-radius: px_to_rem(60);
  color: var(--textColor);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.small {
  height: px_to_rem(30);
}

.medium {
  height: px_to_rem(40);
  font-size: px_to_rem(16);
}

.large {
  height: px_to_rem(52);
}

.link {
  display: inline-block;
  padding: 0;
  height: fit-content;
  color: var(--primaryColor);
  box-shadow: none;
  background-color: transparent;
  border-radius: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.none {
  box-shadow: none;
}

.primary {
  // box-shadow: 0px 2px 4px #524dfc40;
  background: transparent linear-gradient(73deg, #7659eb 0%, #4670fd 100%) 0% 0% no-repeat padding-box;
  color: #fff;
}

.secondary {
  background: transparent linear-gradient(180deg, #fc882e 0%, #f8bb2b 100%) 0% 0% no-repeat padding-box;
  color: #fff;
}

.default {
  background-color: #fff;
}

.line {
  border: solid 1px var(--primaryColor);
  background-color: transparent;
  color: var(--primaryColor);
}

.success {
  background: transparent linear-gradient(257deg, #0a8d0a 0%, #008000 100%) 0% 0% no-repeat padding-box;
  color: #fff;
}

.warning {
  background: transparent linear-gradient(257deg, #ffd000 0%, #e4bd0e 100%) 0% 0% no-repeat padding-box;
}

.danger {
  background: transparent linear-gradient(257deg, #ff0000 0%, #e40e0e 100%) 0% 0% no-repeat padding-box;
  color: #fff;
}

[disabled] {
  opacity: 0.5;
  cursor: default;
}

[data-is-mobile-view='true'] {
  .medium {
    font-size: px_to_rem(16);
  }
}