@import '../../functions';

.preview {
    position: fixed;
    z-index: 5;
}

.taskEditor {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    border: none;
    background-color: #ffffff;
    opacity: 0;
    animation-name: taskEditorShow;
    animation-duration: 0.1s;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes taskEditorShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}