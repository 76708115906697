@import '../../functions';

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: solid 1px #707070;
  overflow-y: auto;
  opacity: 0;
  transition: opacity 0.6s ease;
  z-index: 5;
  color: var(--textColor);
  direction: ltr;

  &.active {
    opacity: 1;
  }
}

.modal {
  position: absolute;
  top: px_to_rem(90);
  left: 50%;
  width: px_to_rem(666);
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  background-color: #ffffff;
  border-radius: px_to_rem(10);
  max-width: calc(100% - #{px_to_rem(40)});
}

.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

[data-direction='rtl'] .modal {
  direction: rtl;
}

.closeButton {
  background-color: var(--lightBackgroundColor);
  width: px_to_rem(37);
  height: px_to_rem(37);
  border-radius: 50%;
  border: none;
  position: absolute;
  top: px_to_rem(-18);
  left: px_to_rem(-18);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;

  svg {
    fill: var(--subTextColor);
    stroke-width: 0;
    z-index: 1;
  }

  &:hover {
    background-color: var(--primaryColor);

    svg {
      fill: #ffffff;
    }
  }
}

[data-is-mobile-view='true'] {
  .container.skin--mobileFullScreen {
    border: unset;

    .modal {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: unset;
      transform: unset;
      border-radius: 0;
      max-width: unset;
      overflow: hidden;

      & > div {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        z-index: 0;

        &::-webkit-scrollbar-track {
          margin: 0;
        }
      }

      .closeButton {
        top: px_to_rem(17, 'mobile');
        width: px_to_rem(31, 'mobile');
        height: px_to_rem(31, 'mobile');

        border: 1px solid #dadada;
        box-shadow: 0 px_to_rem(3, 'mobile') px_to_rem(6, 'mobile') 0 #38374526;
        background: #ffffff;

        &:hover {
          svg {
            fill: #6d6d6d;
          }
        }
      }
    }
  }

  .container.skin--default {
    .modal {
      position: absolute;
      top: unset;
      bottom: px_to_rem(94, 'mobile');
      left: px_to_rem(18, 'mobile');
      right: px_to_rem(18, 'mobile');
      width: unset;
      max-width: unset;
      transform: unset;
      height: auto;
      min-height: calc(100% - #{px_to_rem(94 + 171, 'mobile')});
      max-height: calc(100% - #{px_to_rem(94 + 66, 'mobile')});
      background-color: unset;
      border-radius: unset;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      .closeButton {
        background-color: #edecfe;
        width: px_to_rem(31, 'mobile');
        height: px_to_rem(31, 'mobile');
        top: px_to_rem(-12, 'mobile');
        left: unset;
        right: unset;
        box-shadow: 0 px_to_rem(3, 'mobile') px_to_rem(6, 'mobile') rgba(56, 55, 69, 0.15);

        svg {
          fill: var(--textColor);
          opacity: 0.513;
          position: absolute;
          width: px_to_rem(10, 'mobile') !important;
          height: px_to_rem(10, 'mobile') !important;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          pointer-events: none;
        }

        &:hover {
          background-color: #edecfe;

          svg {
            fill: var(--textColor);
          }
        }
      }

      .modal_content {
        position: relative;
        background-color: #ffffff;
        border-radius: px_to_rem(10, 'mobile');
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
}

[data-is-mobile='true'] {
  .container.skin--mobileFullScreen {
    .modal {
      & > div {
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
    }
  }
}

@each $direction in (ltr, rtl) {
  $dir: $direction;
  $dirOpposite: if-rtl(ltr, rtl);
  $left: if-rtl(left, right);
  $right: if-rtl(right, left);

  [data-direction='#{$direction}'] {
    &[data-is-mobile-view='true'] {
      .container.skin--mobileFullScreen {
        .modal {
          .closeButton {
            #{$left}: px_to_rem(11, 'mobile');
          }
        }
      }

      .container.skin--default {
        .modal {
          .closeButton {
            #{$left}: px_to_rem(-9, 'mobile');
          }
        }
      }
    }
  }
}
