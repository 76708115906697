@import '../../functions';

.container {
  display: inline-block;
  position: relative;
}

.button {
  display: inline-flex;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;

  &:focus,
  &.open {
    outline: none;
  }

  &[disabled='true'] {
    opacity: 0.5;
  }
}

.arrow {
}

.button > span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@each $direction in (ltr, rtl) {
  $dir: $direction;
  $dirOpposite: if-rtl(ltr, rtl);
  $left: if-rtl(left, right);
  $right: if-rtl(right, left);

  [data-direction='#{$direction}'] {
    .arrow {
      margin-#{$right}: px_to_rem(16);
    }
  }
}
