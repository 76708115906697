@import '../../functions';

.container {
  transition: max-height 1s ease;

  :global {
    .cc-socialShare {
      .cc-modal__header {
        padding: 2.3em 2.8em 0 2.8em;
      }

      .cc-modal__content {
        margin: 0;
        padding: 0 2.8em 2.7em 2.8em;
      }

      .cc-modal__footer {
        height: 7em;
        padding: 0 2.8em;
        align-items: center;
      }
    }
  }
}

.previewModal {
  top: px_to_rem(28) !important;
}

.loader {
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease-in-out;
  height: px_to_rem(730);
  max-height: calc(100vh - 10rem);
  width: 100%;
  overflow: hidden;
  position: absolute;

  &.on {
    opacity: 1;
    pointer-events: all;
  }

  &.isFavoritesFormOnly {
    height: px_to_rem(513);

    .preloader,
    .loader_error {
      height: px_to_rem(513);
    }
  }

  .preloader,
  .loader_error {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: px_to_rem(730);
    max-height: calc(100vh - 10rem);
    pointer-events: none;
    transition: opacity 1s ease-in-out;

    &.on {
      opacity: 1;
      pointer-events: all;
    }
  }

  .preloader {
    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      width: px_to_rem(300);
      height: px_to_rem(300);
    }
  }

  .loader_error {
    background-position: center bottom px_to_rem(150);
    background-size: 100% auto;
    background-image: url('/img/oops_error_image.png');
    background-repeat: no-repeat;

    padding: 2rem;
    box-sizing: border-box;

    h1 {
      margin-bottom: px_to_rem(10);
    }

    button {
      min-width: px_to_rem(150);
      margin-top: px_to_rem(25);
    }
  }
}

.item_content {
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease-in-out;
  overflow: hidden;

  &.on {
    opacity: 1;
    pointer-events: all;
  }
}

.horizontalSwipeLayout {
  transform: translate(0, px_to_rem(-7));
  -webkit-transform: translate(0, px_to_rem(-7));

  .slide {
    top: px_to_rem(6) !important;
  }
}

.headerButton {
  font-weight: bold;
  min-width: px_to_rem(144);
  padding: 0 px_to_rem(15);
}

button.previewButton {
  background-color: #5750fad9;
  border-radius: px_to_rem(16);
  color: #fff;
  position: absolute;
  top: px_to_rem(36);
  left: px_to_rem(36);
  right: unset;
  z-index: 1;
  height: px_to_rem(36);
  width: px_to_rem(182);
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  font-size: px_to_rem(16);
  transform-origin: center center;
  transform: scale(1) rotate(0deg) translateZ(0);
  transition: width 0.5s ease-in-out;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;

  div {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding: 0 px_to_rem(13);
    overflow: hidden;
    flex: none;
    transition: width 0.5s ease-in-out, padding 0.5s ease-in-out;

    &:nth-child(1) {
      width: px_to_rem(182);
    }
  }

  svg {
    width: px_to_rem(24) !important;
    height: px_to_rem(36) !important;
  }

  &.previewButton--open {
    width: px_to_rem(58);

    div:nth-child(1) {
      width: px_to_rem(0);
      padding: 0 px_to_rem(0);
    }
  }

  &.previewButton--animate {
    animation-name: previewButtonBell;
    animation-duration: 1s;
    animation-delay: 2s;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: previewButtonBell;
    -webkit-animation-duration: 1s;
    -webkit-animation-delay: 2s;
    -webkit-animation-timing-function: ease-in-out;
  }

  @keyframes previewButtonBell {
    0% {
      transform: scale(1) rotate(0deg) translateZ(0);
    }

    16% {
      transform: scale(1.15) rotate(0deg) translateZ(0);
    }

    33% {
      transform: scale(1.15) rotate(5deg) translateZ(0);
    }

    50% {
      transform: scale(1.15) rotate(0deg) translateZ(0);
    }

    67% {
      transform: scale(1.15) rotate(-5deg) translateZ(0);
    }

    84% {
      transform: scale(1.15) rotate(0deg) translateZ(0);
    }

    100% {
      transform: scale(1) rotate(0deg) translateZ(0);
    }
  }

  @-webkit-keyframes previewButtonBell {
    0% {
      transform: scale(1) rotate(0deg) translateZ(0);
    }

    16% {
      transform: scale(1.15) rotate(0deg) translateZ(0);
    }

    33% {
      transform: scale(1.15) rotate(5deg) translateZ(0);
    }

    50% {
      transform: scale(1.15) rotate(0deg) translateZ(0);
    }

    67% {
      transform: scale(1.15) rotate(-5deg) translateZ(0);
    }

    84% {
      transform: scale(1.15) rotate(0deg) translateZ(0);
    }

    100% {
      transform: scale(1) rotate(0deg) translateZ(0);
    }
  }
}

[data-lang='ar'] {
  button.previewButton {
    width: px_to_rem(206);

    div {
      &:nth-child(1) {
        width: px_to_rem(206);
      }
    }

    &.previewButton--open {
      width: px_to_rem(58);

      div:nth-child(1) {
        width: px_to_rem(0);
        padding: 0 px_to_rem(0);
      }
    }
  }
}

.eye {
  stroke: none;
  fill: #ffffff;
}

.arrow {
  transform: rotate(0deg);
  fill: none;
  stroke: #ffffff;
  margin-top: px_to_rem(1);
}

[data-direction='ltr'] {
  left: unset;
  right: px_to_rem(16);
}

.banner {
  border-top-right-radius: px_to_rem(10);
  border-top-left-radius: px_to_rem(10);
  position: relative;

  img {
    width: 100%;
    height: px_to_rem(239); //333 239
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    transition: height 1s;

    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.banner_image_show {
      opacity: 1;
    }

    &[src*='bad-image'] {
      width: 70%;
      margin: 0 15%;
    }
  }

  .loContainer {
    height: 0;
    transition: height 1s;
    overflow: hidden;
    border-top-right-radius: px_to_rem(10);
    border-top-left-radius: px_to_rem(10);

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: px_to_rem(16);
    pointer-events: none;
  }
}

.loPreview {
  img {
    height: 0;
  }

  .loContainer {
    height: 84vh;
  }

  &::after {
    display: none;
  }
}

.loPreview+.content {
  top: 0;
  margin-top: px_to_rem(0);
  transition: margin-top 0.5s ease-in-out;
}

.videoPlayBtn {
  top: 40%;
}

.content {
  position: relative;
  top: 0;
  padding: 0 px_to_rem(32);

  h1 {
    font-size: px_to_rem(29);
    width: 100%;
    margin: 0;
  }

  h2 {
    margin-top: px_to_rem(5);
  }
}

.row {
  display: flex;
  margin-top: px_to_rem(16);
  position: relative;
}

.bordered {
  border-top: solid 1px var(--lightBackgroundColor);
  border-bottom: solid 1px var(--lightBackgroundColor);
  padding-bottom: px_to_rem(16);
  margin: px_to_rem(16) 0;
  overflow: auto;

  &.overflowVisible {
    overflow: visible;
  }
}

.bordered+.bordered {
  border-top: none;
  margin-top: px_to_rem(-16);
}

.bordered:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  margin-top: px_to_rem(16);
}

.references {
  margin: 1rem 0;

  &:last-child {
    padding-bottom: 1rem;
  }
}

.professions,
.grades {
  display: flex;
  min-width: 90px;

  strong {}
}

.duration {
  min-width: px_to_rem(70);
  color: var(--primaryColor);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  svg {
    margin-top: px_to_rem(3);
    stroke-width: 0;
    fill: var(--primaryColor);
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center;
}

.spacedButton {
  .tooltipContent {
    width: px_to_rem(65);
    text-align: center;
  }
}

.buttonsStart {
  justify-content: flex-start;
}

button.roundButton {
  width: px_to_rem(40);
  height: px_to_rem(40);
  border-radius: 50%;
  background-color: var(--lightBackgroundColor);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: px_to_rem(11);

  &.share {
    justify-content: center;
  }

  svg {
    width: 100%;
    height: 100%;
    transform: scale(1.1);
    stroke-width: 0;
    fill: var(--primaryColor);
    stroke: none;
  }

  &:hover {
    background-color: var(--primaryColor);

    svg {
      fill: #fff;
    }
  }

  button {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

button.quickPreviewButton {
  flex-direction: row-reverse;
  justify-content: end;
  border-radius: 2.5rem;

  box-shadow: 0 0 0 2px transparent;

  &:hover {
    color: #fff;

    .quickPreviweText {
      color: #fff;
    }
  }

  .quickPreviweText {
    overflow: hidden;
    flex-grow: 1;
    padding-left: px_to_rem(6);
    white-space: nowrap;
    color: var(--primaryColor);
    font-weight: 700;
    opacity: 0;

    &:hover {
      color: #fff;
    }
  }

  .quickPreviewIconContainer {
    width: 1.2rem;
    display: flex;
    align-items: center;
  }
}

button.quickPreviewButtonAnimated {
  animation-name: previewButtonAnimation;
  animation-duration: 4.5s;
  animation-iteration-count: 1;

  .quickPreviweTextAnimated {
    animation-name: previewButtonTextAnimation;
    animation-duration: 4.5s;
    animation-iteration-count: 1;
  }
}

@keyframes previewButtonAnimation {
  0% {
    width: 2.5rem;
    box-shadow: 0 0 0 1px transparent;
  }

  3% {
    box-shadow: 0 0 0 1px var(--primaryColor);
  }

  13% {
    width: 8rem;
  }

  80% {
    width: 8rem;
  }

  83% {
    box-shadow: 0 0 0 1px var(--primaryColor);
  }

  100% {
    width: 2.5rem;
    box-shadow: 0 0 0 1px transparent;
  }
}

@keyframes previewButtonTextAnimation {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }
}

[data-direction='ltr'] .roundButton {
  justify-content: flex-end;

  &.share {
    justify-content: center;
  }
}

.itemType {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--subTextColor);

  svg {
    fill: var(--subTextColor);
    transform: scale(1.2);
    margin: px_to_rem(8) 0 px_to_rem(10) 0;
    stroke: none;
  }
}

.label {
  margin-bottom: 0;
  color: var(--primaryLightText);
  font-weight: bold;
  font-size: px_to_rem(16);

  button {
    border: none;
    background-color: transparent;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    display: flex;
    align-items: center;
  }

  svg {
    stroke-width: 5px;
    transition: transform 0.5s;
  }

  &.close {
    svg {
      transform: rotate(0);
    }
  }

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
}

.activityType {
  margin-top: px_to_rem(16);
}

.automaticQuestions {
  margin-top: px_to_rem(16);
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-top: px_to_rem(4);
  }
}

.tableOfContent {
  transition: max-height 0.5s;

  li {
    padding: 0 px_to_rem(16);

    a {
      width: 100%;
      display: flex;
      padding: 0 px_to_rem(16);
      margin: 0 px_to_rem(-16);
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      color: var(--textColor);

      svg {
        display: none;
        stroke-width: 3;
        stroke: var(--primaryColor);
        transform: rotate(90deg);
      }

      &:hover {
        background-color: var(--lightBackgroundColor);
        border-radius: px_to_rem(4);
        text-decoration: underline;

        svg {
          display: block;
        }

        .pageNumber {
          text-decoration: underline;
        }
      }
    }
  }

  &.withScroll {
    padding: px_to_rem(5) 0;
    max-height: px_to_rem(140);
    overflow-y: auto;
    direction: ltr;
  }

  &.noScroll {
    padding: px_to_rem(5) 0;
    max-height: px_to_rem(140);
    overflow-y: hidden;
  }

  &.close {
    max-height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  &.open {
    max-height: 100vh;
    transition: max-height 1s;
  }
}

.bordered .label.close {
  margin-bottom: 0;
}

[data-direction='rtl'] .withScroll li {
  direction: rtl;
}

.listSubject {
  display: inline-block;
  background-color: var(--primaryLight);
  padding: 0 px_to_rem(3);
}

.margined {
  margin-top: px_to_rem(32);
}

.references {
  strong {
    display: inline-block;
  }

  a {
    color: var(--primaryColor);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.pageNumber {
  color: #636363;
  display: inline-block;

  &::before {
    content: '\00a0\00a0\00a0';
  }
}

.tags {
  position: absolute;
  right: px_to_rem(36);
  top: px_to_rem(-6);
  display: flex;
  justify-content: flex-end;
  margin: 0 px_to_rem(3);

  >div {}
}

[data-direction='ltr'] {
  .tags {
    direction: rtl;
    left: unset;
    right: px_to_rem(36);
  }
}

.buttonContainer {
  position: absolute;
  background: transparent;
  display: block;
  align-items: center;
  top: px_to_rem(410);
  z-index: 2;

  .button {
    width: px_to_rem(64);
    height: px_to_rem(64);
    z-index: 1;
    border: none;
    border-radius: 50%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px px_to_rem(9) px_to_rem(37) #031d7273;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      stroke: var(--primaryColor);
      fill: var(--textColor);
    }

    &:focus,
    &:hover {
      svg {
        stroke: var(--primaryColor);
        fill: var(--primaryColor);
      }
    }
  }
}

.courseContent {
  margin-top: px_to_rem(33);
  margin-bottom: px_to_rem(43);

  .courseContentTitle {
    font-size: px_to_rem(16);
    line-height: 1;
    font-weight: 700;
    margin-bottom: px_to_rem(22);
    color: var(--primaryColor);
  }

  .courseContentItem {
    margin-bottom: px_to_rem(12);
    position: relative;
    line-height: 1;

    svg {
      width: px_to_rem(12) !important;
      height: px_to_rem(11) !important;
      position: absolute;
      bottom: px_to_rem(3.2);
      color: var(--primaryColor);
    }
  }
}

.authorizationMessage {
  background-color: #f0effe;
  border-radius: px_to_rem(5);
  padding: px_to_rem(15);
  margin-top: px_to_rem(15);
}

.messageButtons {
  display: flex;
  justify-content: end;
  gap: px_to_rem(10);
  margin: auto;
  margin-top: px_to_rem(5);
  margin-inline-end: 0;
}

.messageButton {
  background-color: #fff !important;
  padding: px_to_rem(15);
  font-weight: bold;
}


.messageSelectButton {
  padding: 0 px_to_rem(16);
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  font-size: px_to_rem(16);
  font-weight: 400;
  background-color: var(--primaryColor) !important;
  border: 1px solid var(--lightBackgroundColor);
  height: px_to_rem(36);
  border-radius: px_to_rem(50);
}

.messageSelectList {
  margin-top: px_to_rem(10);
}

.messageSelectItem a {
  color: var(--textColor);
  text-decoration: none;
}

.swipeAnimate {
  display: none;
}

[data-direction='rtl'] {
  .next {
    left: px_to_rem(-84);
    right: unset;

    svg {
      transform: rotate(90deg);
    }
  }

  .previous {
    right: px_to_rem(-84);
    left: unset;

    svg {
      transform: rotate(-90deg);
    }
  }
}

[data-direction='ltr'] {
  .next {
    right: px_to_rem(-84);
    left: unset;

    svg {
      transform: rotate(-90deg);
    }
  }

  .previous {
    left: px_to_rem(-84);
    right: unset;

    svg {
      transform: rotate(90deg);
    }
  }
}

[data-is-mobile-view='true'] {
  .container {
    :global {
      .cc-socialShare-container {
        height: 100%;
        position: relative;
      }

      .cc-socialShare {
        height: 100%;

        .cc-modal__header {
          padding-top: 2em;
        }

        .cc-modal__content {
          padding: 0 2em 2em 2em;
        }

        .cc-modal__footer {
          height: 7.4em;
          padding: 0 2em;
        }
      }
    }
  }

  .previewModal {
    top: 0 !important;
  }

  .buttonContainer {
    top: px_to_rem(-60, 'mobile');

    .button {
      width: px_to_rem(40, 'mobile');
      height: px_to_rem(40, 'mobile');
    }
  }

  .row {
    flex-wrap: wrap;
    margin: px_to_rem(14) 0;
  }

  &[data-direction='rtl'] {
    .next {
      left: px_to_rem(0, 'mobile');
      right: unset;
    }

    .previous {
      left: px_to_rem(0, 'mobile');
      right: unset;
    }

    .next+.previous {
      left: px_to_rem(60, 'mobile');
    }
  }

  &[data-direction='ltr'] {
    .next {
      right: px_to_rem(0, 'mobile');
      left: unset;
    }

    .previous {
      right: px_to_rem(0, 'mobile');
      left: unset;
    }

    .next+.previous {
      right: px_to_rem(60, 'mobile');
    }
  }

  .stuckMenuClassName {
    background-color: #ffffff;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    &::after {
      display: none;
    }

    &.stuckMenuClassName_title {
      z-index: 2;
    }
  }

  .stuckUpClassName {
    padding: 0 !important;
    box-shadow: unset !important;

    &::before,
    &::after {
      content: '';
      height: 1px;
      display: block;
    }
  }

  .horizontalSwipeLayout {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);

    .slide {
      top: 0 !important;

      &>div {
        height: 100%;
      }
    }
  }

  .container {
    height: 100%;
    user-select: none;
    transition: unset;
  }

  .content {
    padding: 0 px_to_rem(20, 'mobile');
    background: #ffffff;
    top: 0;
    font-size: px_to_rem(18, 'mobile');
    min-height: calc(100% - #{px_to_rem(30, 'mobile')} - #{px_to_rem(16, 'mobile')} - #{px_to_rem(90, 'mobile')});
    padding-bottom: px_to_rem(90, 'mobile');
    margin-top: 0;

    a,
    a * {
      text-decoration: underline;
    }
  }

  .topMarker {
    position: fixed;
    top: px_to_rem(47, 'mobile');
    left: 0;
    right: 0;
    height: 0;
  }

  .titleMarker {
    position: relative;
  }

  .layoutCenterClassName {
    padding-top: 0 !important;
  }

  .mobileContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
      margin: 0;
    }
  }

  .tags {
    min-width: unset;
    top: px_to_rem(18, 'mobile');
    left: unset;
    right: unset;
    position: fixed;

    &>div {
      //width: px_to_rem(58, 'mobile');
      //height: px_to_rem(24, 'mobile');
      font-size: px_to_rem(17, 'mobile');
      line-height: px_to_rem(24, 'mobile');
      padding: 0 px_to_rem(15, 'mobile');
      border-radius: px_to_rem(3, 'mobile');
      box-shadow: 0px px_to_rem(1, 'mobile') px_to_rem(2, 'mobile') #00000029;
    }
  }

  .banner {
    padding-bottom: 47%;

    &>img {
      width: 100%;
      height: auto;
      position: fixed;
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      &[src*='bad-image'] {
        width: 70%;
        margin: px_to_rem(20, 'mobile') 15%;
      }
    }

    &::after {
      bottom: px_to_rem(0, 'mobile');
      background: #fff;
      pointer-events: none;
      height: px_to_rem(40, 'mobile');
    }
  }

  .title {
    font-size: px_to_rem(26, 'mobile');
    line-height: px_to_rem(28, 'mobile');
    margin: px_to_rem(-30, 'mobile') px_to_rem(-20, 'mobile') 0 px_to_rem(-20, 'mobile');
    box-shadow: 0 px_to_rem(30, 'mobile') 0 0 transparent;
    padding-bottom: px_to_rem(16, 'mobile');
    background-color: transparent;
    transition: box-shadow 0.1s linear, background-color 0.1s linear;
    min-height: px_to_rem(35, 'mobile');
    box-sizing: border-box;
  }

  [data-is_top='true'] {
    .title {
      box-shadow: 0 px_to_rem(-60, 'mobile') 0 px_to_rem(60, 'mobile') #ffffff, 0 0 px_to_rem(7, 'mobile') var(--shadowColor);
      background-color: #ffffff;
    }
  }

  .professions {
    min-width: unset;
  }

  .grades {
    max-width: 60%;
    min-width: unset;
    margin-bottom: 1rem;
    color: var(--primaryColor);
  }

  .strongTitle {
    min-width: px_to_rem(100);
  }

  .duration {
    svg {
      width: px_to_rem(15, 'mobile') !important;
      height: px_to_rem(15, 'mobile') !important;
      margin-top: px_to_rem(4, 'mobile');
    }
  }

  .buttonsEnd {
    position: relative;
    top: px_to_rem(-7, 'mobile');

    .roundButton {
      width: px_to_rem(37, 'mobile');
      height: px_to_rem(37, 'mobile');
      padding: 0;
      background: rgba(87, 80, 250, 0.09);

      svg {
        width: px_to_rem(15, 'mobile') !important;
        height: px_to_rem(15, 'mobile') !important;
      }
    }
  }

  .buttonsBottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: px_to_rem(19, 'mobile') 0 px_to_rem(14, 'mobile') 0;
    height: px_to_rem(74, 'mobile');
    box-sizing: border-box;
    background: #ffffff;
    border-top: 1px solid #dfdfdf;
    z-index: 1;

    .spacedButton {

      &+button,
      &+a {}
    }

    a,
    a * {
      text-decoration: unset;
    }
  }

  .itemType {
    text-align: center;
    align-items: center;
    justify-content: flex-start;

    div {
      width: min-content;
      font-size: px_to_rem(14, 'mobile');
    }
  }

  .yearCycles {
    color: #ffffff;
    background: #5750fa;
    border-radius: px_to_rem(3, 'mobile');
    padding: px_to_rem(5, 'mobile') px_to_rem(15, 'mobile');
    margin: px_to_rem(30, 'mobile') 0 px_to_rem(3, 'mobile') 0;
  }

  .tabs {
    border-bottom: 1px solid #cfd0d5;
    padding-top: px_to_rem(25, 'mobile');

    &>div {
      margin: 0 auto;

      &>div:first-child {
        width: 100%;
        justify-content: space-around;
      }
    }
  }

  .bordered {
    width: 100%;
    height: unset;
    overflow: hidden;
    border-top: solid 1px var(--lightBackgroundColor);
    border-bottom: 0;
    padding-bottom: 0;

    &:first-child {
      border-top: 0;
    }
  }

  .label:empty {
    display: none;
  }

  .label {
    color: var(--textColor);
    font-size: px_to_rem(18, 'mobile');
  }

  .tableOfContentAlwaysOpen {
    margin: 0 px_to_rem(-16, 'mobile');
  }

  .videoPlayBtn {
    position: fixed;
    top: px_to_rem(70, 'mobile');
    width: px_to_rem(45, 'mobile') !important;
    height: px_to_rem(45, 'mobile') !important;
    background: transparent linear-gradient(220deg, #5750fa 0%, #1e5afe 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0 px_to_rem(2, 'mobile') px_to_rem(4, 'mobile') #524dfc40;

    svg {
      pointer-events: none;
      top: 50%;
      left: 50%;
      right: unset;
      bottom: unset;
      transform: translate(-50%, -50%) scale(1.4);
      -webkit-transform: translate(-50%, -50%) scale(1.4);
    }

    &:hover {
      transform: translate(-50%, -50%) scale(1);
      -webkit-transform: translate(-50%, -50%) scale(1);
    }
  }

  .courseContent {
    .courseContentTitle {
      color: unset;
      font-size: unset;
      margin-bottom: px_to_rem(12, 'mobile');
    }
  }

  .audiencesBtn {
    text-decoration: none;
    font-size: px_to_rem(18, 'mobile');
    font-weight: bold;
    line-height: px_to_rem(32, 'mobile');
    height: px_to_rem(32, 'mobile');
    margin: 0;
    padding: 0 px_to_rem(13, 'mobile');
    color: var(--primaryColor);
    background: rgba(87, 80, 250, 0.09);
    border-radius: px_to_rem(24, 'mobile');

    &>span {
      overflow: visible;
    }
  }

  .audiences {
    padding: 0;
    max-width: unset;
    max-height: unset;

    a {
      margin: 0;
      padding: 0;
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--textColor);
      text-decoration: unset;
    }
  }

  .swipeAnimate {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease-in-out;

    &.swipeAnimate_show {
      opacity: 1;
      pointer-events: all;
    }

    .swipeAnimate_container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      background: #8984fc;
      width: px_to_rem(320, 'mobile');
      border-radius: px_to_rem(10, 'mobile');
      color: #ffffff;
      font-size: px_to_rem(17, 'mobile');
      line-height: px_to_rem(25, 'mobile');
      padding: px_to_rem(19, 'mobile') px_to_rem(18, 'mobile');
      box-sizing: border-box;

      .swipeAnimate_text {
        margin-bottom: px_to_rem(9, 'mobile');
      }

      .swipeAnimate_icon {
        text-align: center;

        svg {
          fill: currentColor;
        }
      }
    }
  }

  .headerButton {
    height: px_to_rem(47);
    min-width: px_to_rem(174);
  }
}

[data-is-mobile='true'] {
  .mobileContainer {
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

@each $direction in (ltr, rtl) {
  $dir: $direction;
  $dirOpposite: if-rtl(ltr, rtl);
  $left: if-rtl(left, right);
  $right: if-rtl(right, left);

  [data-direction='#{$direction}'] {
    .eye {
      margin-#{$left}: px_to_rem(8);
    }

    .arrow {
      margin-#{$left}: px_to_rem(7);
    }

    .professions,
    .grades {
      margin-#{$left}: px_to_rem(40);

      strong {
        margin-#{$left}: px_to_rem(8);
      }
    }

    .duration {
      svg {
        margin-#{$left}: px_to_rem(7);
      }
    }

    .spacedButton {
      margin-#{$left}: px_to_rem(16);
    }

    .itemType {
      margin-#{$left}: px_to_rem(16);
    }

    .label {
      svg {
        margin-#{$right}: px_to_rem(16);
      }
    }

    .tableOfContent {
      &.noScroll {
        margin-#{$right}: px_to_rem(-16);
      }
    }

    .references {
      strong {
        margin-#{$left}: px_to_rem(10);
      }
    }

    .tags {
      >div {
        margin-#{$right}: px_to_rem(13);
      }
    }

    .courseContent {
      .courseContentItem {
        padding-#{$right}: px_to_rem(20);

        svg {
          #{$right}: px_to_rem(0);
        }
      }
    }

    &[data-is-mobile-view='true'] {
      .stuckUpClassName {
        #{$right}: 0 !important;
      }

      .tags {
        padding-#{$right}: px_to_rem(14, 'mobile');

        &>div {
          margin-#{$right}: 0;
          margin-#{$left}: px_to_rem(11, 'mobile');
        }
      }

      .title {
        padding-#{$right}: px_to_rem(20, 'mobile');
        padding-#{$left}: px_to_rem(45, 'mobile');
      }

      .professions {
        margin-#{$left}: 0;
      }

      .grades {
        margin-#{$left}: px_to_rem(23, 'mobile');
      }

      .duration {
        svg {
          margin-#{$left}: px_to_rem(9, 'mobile');
        }
      }

      .buttonsBottom {
        .spacedButton {
          margin-#{$left}: 0;

          &+button,
          &+a {
            margin-#{$right}: px_to_rem(24, 'mobile');
          }
        }
      }

      .tabs {
        &>div {
          &>div:first-child {
            &>div {
              margin-#{$left}: 0;
            }
          }
        }
      }
    }
  }
}