/*#region direction mode */

:root {
  --primary-color: #5750fa;
  --primary-light-bg-color: #ecebff;
  --light-bg-color: #ececf0;
  --dark-bg-color: #474747;
  --basic-color: #fff;
  --primary-text-color: #6c68ba;
  --text-color: #383745;
  --sub-text-color: #6d6d6d;
  --scrollbar-track-color: #ececf0;
  --scrollbar-thumb-color: #7f7f90;
  --shadow-color: #5e5d7e14;
  --error-color: #d71515;

  --secondary-color: #6ddc98;
  --secondary-light-color: #89e0aa;
  --success-color: #06a862;
  --success-light-color: #33aa76;
  --warning-color: #facd50;
  --warning-light-color: #ffdb7a;
  --danger-color: #8f2020;
  --danger-light-color: #a84242;

  --base-font-family: 'AlmoniNeue';
  --base-font-family-ar: 'Abraham';
  --base-pc-font-size: 16;
  --base-mobile-font-size: 18;

  --base-proportion: 1;
}
@function px-to-rem($px, $baseFS: 10) {
  $base: $baseFS;
  $rem: calc($px / $base);
  $result: calc(#{$rem}rem / var(--base-proportion));
  @return $result;
}

$dir: ltr !default;

@function if-ltr($if, $else: null) {
  @if $dir != rtl {
    @return $if;
  } @else {
    @return $else;
  }
}

@function if-rtl($if, $else: null) {
  @return if-ltr($else, $if);
}

$dirOpposite: if-rtl(ltr, rtl);
$left: if-rtl(left, right);
$right: if-rtl(right, left);

@function side-values($values) {
  @if $dir == ltr and length($values) >= 4 {
    // Reorder right and left positions in list
    @return nth($values, 1) nth($values, 4) nth($values, 3) nth($values, 2);
  } @else {
    @return $values;
  }
}

@function corner-values($values) {
  @if $dir == rtl and length($values) > 1 {
    // Reorder right and left positions in list
    @if length($values) == 2 {
      @return nth($values, 2) nth($values, 1);
    } @else if length($values) == 3 {
      @return nth($values, 2) nth($values, 1) nth($values, 2) nth($values, 3);
    } @else {
      @return nth($values, 2) nth($values, 1) nth($values, 4) nth($values, 3);
    }
  } @else {
    @return $values;
  }
}

@mixin if-ltr {
  @if $dir != rtl {
    @content;
  }
}

@mixin if-rtl {
  @if $dir == rtl {
    @content;
  }
}

@mixin direction-mode {
  @each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
      @content;
    }
  }
}

/*#endregion */
