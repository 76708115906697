@import url('./fonts/fonts.css');

:root {
  --primaryColor: #5750fa;
  --primaryLight: #5650fa3b;
  --primaryLightBackground: #ecebff;
  --primaryLightText: #6c68ba;
  --secondaryColor: #ffffff;
  --textColor: #383745;
  --subTextColor: #6d6d6d;
  --lightBackgroundColor: #ececf0;
  --shadowColor: #5e5d7e14;
  --hrLine: linear-gradient(90deg,
      #ffffff 0%,
      #7f7f9066 19%,
      #38374580 49%,
      #7f7f9066 81%,
      #ffffff 100%);
  --base-proportion: 1.6 !important;
  --paleLavender: #DAD9FC;
  --fontSegoeUI: 'SegoeUI';
  --grayFont_1: #9b9b9b;
}

/* @media only screen and (max-width: 1600px) {
  :root {
    font-size: 93.75%;
  }
}

@media only screen and (max-width: 1366px) {
  :root {
    font-size: 87.5%;
  }
} */

html {
  height: 100%;
}

html * {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'AlmoniNeue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

.root-scroll {
  overflow: auto;
  height: 100%;
}

* {
  font-family: 'AlmoniNeue';
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  border-radius: 1rem;
  background-color: #ececf0;
}

#rootScroll::-webkit-scrollbar-track {
  margin: 3.75rem 0 0;
}

::-webkit-scrollbar-thumb {
  background-color: #7f7f90;
  outline: 1px solid #ececf0;
  border-radius: 1rem;
}

[data-horizontal-scroller-on='true'] {
  transition: none !important;
}

[data-is-horizontal-scroller='true'] {
  cursor: grab;
}

[data-is-horizontal-scroller='true'][data-horizontal-scroller-on='true'] {
  cursor: grabbing !important;
}

[data-is-horizontal-scroller='true'],
[data-is-horizontal-scroller='true'] * {
  user-select: none !important;
  -webkit-user-drag: none !important;
  -khtml-user-drag: none !important;
  -moz-user-drag: none !important;
  -o-user-drag: none !important;
  user-drag: none !important;
}

[data-is-mobile-scroller='true']::-webkit-scrollbar-track {
  margin: 3.57143rem 0 4.28571rem 0;
}

[data-is-mobile-scroller='true'][data-page='search']::-webkit-scrollbar-track {
  margin: 10.6rem 0 4.28571rem 0;
}

[data-is-mobile-scroller='true'][data-page='catalog']::-webkit-scrollbar-track {
  margin: 17.3rem 0 4.28571rem 0;
}

[data-is-mobile-scroller='true'][data-page='recommendations']::-webkit-scrollbar-track {
  margin: 17.3rem 0 4.28571rem 0;
}

[data-is-mobile-scroller='true']::-webkit-scrollbar {
  background: #f0f1f6;
}