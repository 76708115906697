@import '../../functions';

.title {
  margin-top: 0;
  margin-bottom: px_to_rem(24);
  color: var(--textColor);
}

h1.title {
  font-size: px_to_rem(28);
  line-height: px_to_rem(28);
}

h2.title {
  margin-bottom: px_to_rem(8);
  font-size: px_to_rem(22);
  line-height: px_to_rem(22);
}

h3.title {
  font-size: px_to_rem(18);
  line-height: px_to_rem(18);
}

h4.title {
  font-size: px_to_rem(16);
  line-height: px_to_rem(16);
}

[data-is-mobile-view='true'] {
  h2.title {
    font-size: px_to_rem(20, 'mobile');
    line-height: px_to_rem(20, 'mobile');
  }
}