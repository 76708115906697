@import '../../functions';

.dominateSystemMessage {
    position: fixed;
    z-index: 55;
}

.systemMessagePopup {
  min-height: px_to_rem(350);
  max-height: 80vh;
  display: flex;
  flex-direction: column;

  .systemMessagePopup_scroller {
    flex: 1;
    overflow: auto;
    margin: px_to_rem(36) px_to_rem(42);
    color: var(--textColor);

    .systemMessagePopup_title {
        font-size: px_to_rem(20);
        font-weight: 700;
        margin-bottom: px_to_rem(7);
    }

    .systemMessagePopup_content {
        font-size: px_to_rem(16);

        p {
            margin: 0;
            padding: 0;

            &:empty::before {
                content: '\00a0 ';
            }
        }
    }
  }

  .systemMessagePopup_footer {
      flex: none;
      border-top: 1px solid rgba(127, 127, 144, 0.28);
      padding: px_to_rem(0) px_to_rem(42);
      height: px_to_rem(82);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .systemMessagePopup_button{
        font-weight: 700;
        color: var(--primaryColor);
        border: 1px solid currentColor;
        box-shadow: none;
        height: px_to_rem(36);
        text-align: center;
      }
      .systemMessagePopup_buttonText {
          min-width: px_to_rem(100);
          padding: 0 px_to_rem(10);
          font-size: px_to_rem(16);

      }
    }

    .systemMessagePopup_checkbox_footer {
        margin: 0 auto;
        padding-bottom: px_to_rem(21);
    }
}

[data-is-mobile-view='true'] {
    .modal {
        & > div {
            display: flex;
            flex-direction: column;
        }
    }

    .systemMessagePopup {
        min-height: unset;
        max-height: unset;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        overflow: hidden;

        .systemMessagePopup_scroller {
            flex: 1;
            overflow: auto;
            margin: unset;
            margin-top: px_to_rem(33, 'mobile');
            padding: unset;
            padding-bottom: px_to_rem(15, 'mobile');
        
            .systemMessagePopup_title {
                font-size: px_to_rem(20, 'mobile');
                line-height: px_to_rem(30, 'mobile');
                font-weight: bold;
                margin-bottom: px_to_rem(14, 'mobile');
            }
        
            .systemMessagePopup_content {
                font-size: px_to_rem(17, 'mobile');
        
                img, video {
                    max-width: 100%;
                }
            }
        }

        .systemMessagePopup_footer {
            border-top: unset;
            padding: px_to_rem(14, 'mobile') 0 px_to_rem(30, 'mobile') 0;
            height: unset;
      
            .systemMessagePopup_button{
              height: px_to_rem(42);
              text-align: center;
            }
            .systemMessagePopup_buttonText {
                min-width: px_to_rem(136, 'mobile');
                padding: 0 px_to_rem(10, 'mobile');
                font-size: px_to_rem(17, 'mobile');
                box-sizing: border-box;
            }
        }
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .systemMessagePopup {
          .systemMessagePopup_scroller {
            .systemMessagePopup_content {
                padding-#{$left}: px_to_rem(10);
            }
          }
        }
        
        &[data-is-mobile-view='true'] {
            .systemMessagePopup {
                .systemMessagePopup_scroller {
                    margin-#{$right}: px_to_rem(21, 'mobile');
                    margin-#{$left}: px_to_rem(6, 'mobile');
                    padding-#{$left}: px_to_rem(15, 'mobile');

                    .systemMessagePopup_content {
                        padding-#{$left}: unset;
                    }
                }
            }
        }
    }
}