@font-face {
  font-family: 'Abraham';
  src: url('Abraham-Light.eot');
  src: url('Abraham-Light.eot?#iefix') format('embedded-opentype'), url('Abraham-Light.woff2') format('woff2'), url('Abraham-Light.woff') format('woff');

  font-weight: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Abraham';
  src: url('Abraham-Medium.eot');
  src: url('Abraham-Medium.eot?#iefix') format('embedded-opentype'), url('Abraham-Medium.woff2') format('woff2'), url('Abraham-Medium.woff') format('woff');
  
  font-weight: bold;
  font-weight: 700;
}
