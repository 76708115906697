@import '../../functions';

[cc-data-lang='ar'] .cc-Button {
  font-family: var(--base-font-family-ar);
}

.cc-Button {
  font-family: var(--base-font-family);
  color: var(--text-color);
  border-radius: px-to-rem(65);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0 px-to-rem(20);

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &--x-small {
    height: px-to-rem(20);
    font-size: px-to-rem(11);
    padding: 0 px-to-rem(15);
  }

  &--small {
    height: px-to-rem(30);
    font-size: px-to-rem(14);
    padding: 0 px-to-rem(15);
  }

  &--medium {
    height: px-to-rem(35);
    font-size: px-to-rem(16);
  }

  &--large {
    height: px-to-rem(40);
    font-size: px-to-rem(18);
    padding: 0 px-to-rem(25);
  }

  &--x-large {
    height: px-to-rem(50);
    font-size: px-to-rem(23);
    padding: 0 px-to-rem(25);
  }

  &--round {
    border-radius: 50%;
    padding: px-to-rem(5);
    display: flex;
    justify-content: center;
    align-items: center;

    &.cc-Button--x-small {
      height: px-to-rem(30);
      width: px-to-rem(30);
    }

    &.cc-Button--small {
      height: px-to-rem(35);
      width: px-to-rem(35);
    }

    &.cc-Button--medium {
      height: px-to-rem(40);
      width: px-to-rem(40);
    }

    &.cc-Button--large {
      height: px-to-rem(50);
      width: px-to-rem(50);
    }

    &.cc-Button--x-large {
      height: px-to-rem(60);
      width: px-to-rem(60);
    }
  }

  &.cc-Button--contained {
    &.cc-Button--none {
      background: #fff;
      color: inherit;
    }

    &.cc-Button--primary {
      background: transparent linear-gradient(73deg, var(--primary-color) 0%, var(--primary-text-color) 100%) 0% 0% no-repeat padding-box;
      color: #fff;
    }

    &.cc-Button--secondary {
      background: transparent linear-gradient(180deg, var(--secondary-color) 0%, var(--secondary-light-color) 100%) 0% 0% no-repeat padding-box;
      color: #fff;
    }

    &.cc-Button--success {
      background: transparent linear-gradient(257deg, var(--success-color) 0%, var(--success-light-color) 100%) 0% 0% no-repeat padding-box;
      color: #fff;
    }

    &.cc-Button--warning {
      background: transparent linear-gradient(180deg, var(--warning-color) 0%, var(--warning-light-color) 100%) 0% 0% no-repeat padding-box;
    }

    &.cc-Button--danger {
      background: transparent linear-gradient(180deg, var(--danger-color) 0%, var(--danger-light-color) 100%) 0% 0% no-repeat padding-box;
      color: #fff;
    }

    &.cc-Button--light {
      background: var(--primary-light-bg-color);
      color: var(--primary-color);
    }
  }

  &--outlined {
    background-color: transparent;
    border-width: 1pt;
    border-style: solid;

    &.cc-Button--none {
      border-color: inherit;
      color: inherit;
    }

    &.cc-Button--primary {
      border-color: var(--primary-color);
      color: var(--primary-color);
    }

    &.cc-Button--secondary {
      border-color: var(--primary-color);
      color: var(--primary-color);
    }

    &.cc-Button--success {
      border-color: var(--success-color);
      color: var(--primary-color);
    }

    &.cc-Button--warning {
      border-color: var(--warning-color);
      color: var(--primary-color);
    }

    &.cc-Button--danger {
      border-color: var(--danger-color);
      color: var(--primary-color);
    }

    &.cc-Button--light {
      color: var(--primary-light-bg-color);
    }
  }

  &--link,
  &--text {
    display: inline-block;
    padding: 0;
    height: fit-content;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;

    &.cc-Button--none {
      color: inherit;
    }

    &.cc-Button--primary {
      color: var(--primary-color);
    }

    &.cc-Button--secondary {
      color: var(--primary-color);
    }

    &.cc-Button--success {
      color: var(--primary-color);
    }

    &.cc-Button--warning {
      color: var(--primary-color);
    }

    &.cc-Button--danger {
      color: var(--primary-color);
    }

    &.cc-Button--light {
      color: var(--primary-light-bg-color);
    }
  }

  &--link {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}
