@import '../../functions';

.container {
  display: inline-block;
  position: relative;
  height: fit-content;
}

.hidden {
  opacity: 1;
}

.tip {
  display: inline-block;
  background-color: #ffffff;
  border: solid px_to_rem(1) #c6c5e0;
  border-radius: px_to_rem(10);
  padding: px_to_rem(5);
  position: absolute;
  color: var(--subTextColor);
  z-index: 1;
  left: 0;
  opacity: 1;
  font-size: px_to_rem(15);
  line-height: 1.15;
}

.tip::before {
  content: '';
  position: absolute;
  border: solid px_to_rem(1) #c6c5e0;
  background-color: #ffffff;
  border-width: 0 px_to_rem(1) px_to_rem(1) 0;
  display: inline-block;
  padding: px_to_rem(3);
  z-index: -1;
}

.tip.top {
  top: 0;
  bottom: unset;
  transform: translate(0, calc(-100% - 0.5rem));
}

.tip.top::before {
  top: unset;
  bottom: px_to_rem(-1);
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
}

.tip.bottom {
  bottom: 0;
  top: unset;
  transform: translate(0, calc(100% + 0.5rem));
}

.tip.bottom::before {
  top: px_to_rem(-1);
  bottom: unset;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-135deg);
}
