@import '../../functions';

.container {
  width: 100%;
  padding: 0 px_to_rem(44);
  min-height: calc(100vh - #{px_to_rem(60)});
  font-size: px_to_rem(20);
}

.button {
  padding: 0 px_to_rem(44);
  margin-top: px_to_rem(16);
}

.image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  speak: none;
  max-width: px_to_rem(396);
  width: 80%;
}
