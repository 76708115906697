@font-face {
  font-family: 'AlmoniNeue';
  src: url('AlmoniNeueDL4.0AAA-Medium.otf');
  font-display: swap;
}
  
@font-face {
  font-family: 'AlmoniNeue';
  src: url('AlmoniNeueDL4.0AAA-Thin.otf');
  font-weight: 100;
  font-display: swap;
}
  
@font-face {
  font-family: 'AlmoniNeue';
  src: url('AlmoniNeueDL4.0AAA-Bold.otf');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'AlmoniNeue';
  src: url('AlmoniNeueDL4.0AAA-U-Black.otf');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'AlmoniNeue';
  src: url('AlmoniNeueDL4.0AAA-Light.otf');
  font-weight: 300;
  font-display: swap;
}