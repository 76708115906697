/*
 * Noto Sans Hebrew (Hebrew) http://www.google.com/fonts/earlyaccess
 */
 @font-face {
  font-family: 'Noto Sans Hebrew';
  font-style: normal;
  font-weight: 400;
  src: url('NotoSansHebrew-Regular.eot');
  src: url('NotoSansHebrew-Regular.eot?#iefix') format('embedded-opentype'),
       url('NotoSansHebrew-Regular.woff2') format('woff2'),
       url('NotoSansHebrew-Regular.woff') format('woff'),
       url('NotoSansHebrew-Regular.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Hebrew';
  font-style: normal;
  font-weight: 700;
  src: url('NotoSansHebrew-Bold.eot');
  src: url('NotoSansHebrew-Bold.eot?#iefix') format('embedded-opentype'),
       url('NotoSansHebrew-Bold.woff2') format('woff2'),
       url('NotoSansHebrew-Bold.woff') format('woff'),
       url('NotoSansHebrew-Bold.ttf') format('truetype');
  font-display: swap;
}