@import '../../functions';

.message_padding {
  position: relative;
  height: 0;
  transition: height 0.25s ease-in-out;
}

.message_contaner {
    position: fixed;
    height: 0;
    transition: height 0.25s ease-in-out;
    overflow: hidden;
    top: 0;
    width: calc(100vw - 0.5rem - #{px_to_rem(179)});
    z-index: 4;

    .message {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;

        & > a {
          text-decoration: none;
        }

        .message_content {
            //min-height: px_to_rem(46);
            font-size: px_to_rem(16);
            padding: px_to_rem(13) px_to_rem(40);
            color: #ffffff;
            background: transparent linear-gradient(270deg, #1AA85C 50%, #24CD78 100%) 0% 0% no-repeat padding-box;

            p {
                margin: 0;
                padding: 0;

                &:empty::before {
                    content: '\00a0 ';
                }
            }

            a {
                color: #ffffff;
            }
        }
    }
}

.closeButton {
    background-color: rgba(255,255,255,0.2);
    width: px_to_rem(22);
    height: px_to_rem(22);
    border-radius: 50%;
    border: none;
    position: absolute;
    top: px_to_rem(12);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.9;
    padding: 0;
    text-align: center;

    svg {
      fill: #ffffff;
      stroke-width: 0;
      width: px_to_rem(10) !important;
    }

    &:hover {
        opacity: 1;
    }
  }

  [data-is-mobile-view='true'] {
    .message_contaner {
      width: 100vw;
      left: 0;
      right: 0;
    }
  }

  @each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
      .closeButton {
        #{$left}: px_to_rem(22);
      }
    
      .message_contaner {
        border-top-#{$right}-radius: px_to_rem(6);
      }
    
      &[data-is-mobile-view='true'] {
        .message_contaner {
          border-top-#{$right}-radius: 0;
        }
      }
    }
}