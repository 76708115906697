@import '../../functions';

.elements {
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;

  &.disciplines {
    justify-content: start;
  }
}

.elementsLine {
  width: 100%;
  display: flex;
  justify-content: center;
}

.disciplinesTitle {
  span {
    font-size: px_to_rem(22);
  }
}

.doneMessage {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  gap: px_to_rem(10);
}

.manySchoolsMessage {
  padding: px_to_rem(10) px_to_rem(20);
  background-color: #f0f1f6;
  border-radius: px_to_rem(7);
}

.openingMessageTitle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  gap: px_to_rem(10);
}

.openingMessage {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .annualUpdateDetails {
    overflow-y: scroll;
    padding: px_to_rem(16) px_to_rem(16);
    background-color: #f0f1f6;
    box-sizing: border-box;
    border-radius: px_to_rem(7);

    .grades {
      margin-top: px_to_rem(5);
      padding-right: 0;
      list-style-type: none;

      .gradeLine {
        line-height: px_to_rem(24);

        .gradeName {
          padding-left: px_to_rem(7);
        }
      }
    }
  }

  .openingMessageSchools {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    .openingMessageSchoolsTitle {
      flex: none;

      button {
        text-decoration: underline;
      }
    }

    .openingMessageSchoolsList {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 1rem 0;

      button {
        text-decoration: underline;
      }
    }

    .openingMessageSchoolsText {
      flex: none;
      font-size: px_to_rem(15);
    }
  }
}
