.horizontalSwipeLayout { 
    // position: relative;
    width: 100%;
    overflow: hidden;
    
    &:not(.isAnimate):not(.isStart) {
        height: auto !important;
        .slide.slide_inner {
            position: relative;
        }
    }

    &.isAnimate {
        $animatestep: 0.5s;
        transition: height #{$animatestep} ease-in-out;
        .slide {
            transition: opacity #{$animatestep} ease-in-out, transform #{$animatestep} ease-in-out;
        }
    }

    .slide {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: auto;
        opacity: 0;
        pointer-events: none;
        
        &.slide_inner {
            transform: translate(0%, 0);
            -webkit-transform: translate(0%, 0);
            opacity: 1;
            pointer-events: all;
        }
    }
}

[data-direction="rtl"] .horizontalSwipeLayout .slide {
    &.slide_before {
        transform: translate(100%, 0);
        -webkit-transform: translate(100%, 0);
    }

    &.slide_after {
        transform: translate(-100%, 0);
        -webkit-transform: translate(-100%, 0);
    }
}

[data-direction="ltr"] .horizontalSwipeLayout .slide {
    &.slide_before {
        transform: translate(-100%, 0);
        -webkit-transform: translate(-100%, 0);
    }

    &.slide_after {
        transform: translate(100%, 0);
        -webkit-transform: translate(100%, 0);
    }
}

[data-is-mobile-view='true'] {
    .horizontalSwipeLayout {
        height: 100% !important;

        &:not(.isAnimate):not(.isStart) {
            height: 100% !important;
        }
    
        .slide {
            height: 100%;
        }
    }
}