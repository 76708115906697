@import '../../functions';

.videoModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;

  .videoModal_bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
  }

  .videoModal_video {
    position: absolute;
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    top: 50%;
    left: 50%;
    max-width: 70%;
    max-height: 70%;
    transform: translate(-50%, -50%);
  }

  .videoModal_youtube {
    width: 70vw;
    height: 39.375vw;
    aspect-ratio: 16 / 9;
    object-fit: contain;

    iframe {
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  .loader {
    position: absolute;
    display: block;
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.2s ease-in-out;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    width: px_to_rem(75) !important;
    height: px_to_rem(75) !important;
    border-radius: 50%;
    border-width: px_to_rem(5);
    border-style: solid;
    //border-color: rgba(87, 80, 250, 1) rgba(87, 80, 250, 0.25) rgba(87, 80, 250, 0.25) rgba(87, 80, 250, 0.25);
    border-color: rgba(255, 255, 255, 1) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.25);
  }

  .videoModal_closeButton {
    background-color: var(--lightBackgroundColor);
    width: px_to_rem(34);
    height: px_to_rem(34);
    border-radius: 50%;
    border: none;
    position: absolute;
    top: px_to_rem(16);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
  
    svg {
      fill: var(--subTextColor);
      stroke-width: 0;
      z-index: 1;
    }
  
    &:hover {
      background-color: var(--primaryColor);
  
      svg {
        fill: #ffffff;
      }
    }
  }

  &.videoModal_isLoading {
    .loader {
      animation-name: videoLoder;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    @keyframes videoLoder {
      from {transform: translate(-50%, -50%) rotate(0deg);}
      to {transform: translate(-50%, -50%) rotate(360deg);}
    }
  }

  &.videoModal_isNotLoading {
    .videoModal_video {
      opacity: 1;
      pointer-events: all;
    }
    .loader {
      opacity: 0;
      pointer-events: none;
    }
  }
}
  
[data-direction='rtl'] .videoModal .videoModal_closeButton {
  right: px_to_rem(16);
}
[data-direction='ltr'] .videoModal .videoModal_closeButton {
  left: px_to_rem(16);
}

[data-is-mobile-view='true'] {
  .videoModal {
    width: unset;
    height: unset;
    z-index: 92147483647;
    
    .videoModal_video {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  
    .videoModal_youtube {
      width: 100%;
      height: 100%;
      aspect-ratio: unset;
    }
  }
}